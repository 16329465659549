app.service('authHelper', ['$window', 'INTERCOM', 'storageService', 'apiHelper', '$http', '$location', 'CONSTANTS','$rootScope',
  function ($window, INTERCOM, storageService, apiHelper, $http, $location, CONSTANTS, $rootScope) {

    var authHelper = this;
    var shared_data = { 'me_orig': {}, 'me': {} };
    const LOGIN_PREFIX = 'login_';
    const LOGIN_PREFIX_ORIG = 'loginOrig_';
    const IMPERSONATION_KEY = 'is_impersonation';
    const IMPERSONATION_ACTIVE = 'active';
    const IMPERSONATION_INACTIVE = 'inactive';

    authHelper.getSharedData = function () {
      return shared_data;
    };

    authHelper.getLoginPrefix = function () {
      return authHelper.isImpersonation() ? LOGIN_PREFIX : LOGIN_PREFIX_ORIG;
    };

    authHelper.getLoginPrefixOrig = function () {
      return LOGIN_PREFIX_ORIG;
    };

    authHelper.setLogin = function (login, use_orig_login) {

      if (use_orig_login) {
        storageService.getLocalStorage()[LOGIN_PREFIX_ORIG] = JSON.stringify(login);
      } else {
        storageService.getLocalStorage()[authHelper.getLoginPrefix()] = JSON.stringify(login);
      }
    };

    authHelper.getLogin = function (use_orig_login) {
      if (!storageService.getLocalStorage()) {
        return {};
      }

      var login = (use_orig_login)
        ? storageService.getLocalStorage()[LOGIN_PREFIX_ORIG]
        : storageService.getLocalStorage()[authHelper.getLoginPrefix()];

      if (!login) {
        return {};
      }

      return JSON.parse(login);
    };

    var getMyDetails = function () {
      var myDetailsStr = sessionStorage.myDetails;
      if (myDetailsStr) {
        var myDetails = JSON.parse(myDetailsStr);
        return myDetails;
      }
      return [];
    };

    authHelper.isUserAdmin = function (use_orig_login) {
      var myDetails = getMyDetails();
      return myDetails.user_type_name === 'admin';
    };

    authHelper.isUserPayer = function (use_orig_login) {
      var myDetails = getMyDetails();
      return myDetails.user_type_name === 'payer';
    };

    authHelper.isUserClient = function (use_orig_login) {
      var myDetails = getMyDetails();
      return myDetails.user_type_name === 'client';
    };

    authHelper.isLoginPage = function () {
      return ($window.location.pathname === '/login/');
    };

    authHelper.isCorrectPortalInPath = function () {
      if ($window.location.pathname === '/admin/' && !authHelper.isUserAdmin()) {
        return false;
      }

      if ($window.location.pathname === '/client/' && !authHelper.isUserClient()) {
        return false;
      }

      if ($window.location.pathname === '/payer/' && !authHelper.isUserPayer()) {
        return false;
      }

      return true;
    };



    authHelper.isPublicRoute = function () {
      var publicRoutes = [
        '/Help',
        '/FAQs',
        '/PayerExpertiseForm'
      ];

      for (var i = 0; i < publicRoutes.length; i++) {
        if ($location.path().indexOf(publicRoutes[i]) == 0) {
          return true;
        }
      }
    };

    var redirectToBackendLoginPage = function () {
      var frontendReturnURL = $location.absUrl();
      window.location.href = apiHelper.getApiUrl() +
      '/auth/login/?next=' + frontendReturnURL;
    };

    authHelper.removeStaleLoginDetails = function () {
      sessionStorage.removeItem(CONSTANTS.LOGIN_DETAILS_KEY);
    };

    authHelper.isLoggedIn = function () {
      var url = apiHelper.getApiUrl() + '/api/mydetails/';
      return $http.get(url).then(
        function (result) {
          var myDetails = result.data;
          sessionStorage.setItem(CONSTANTS.LOGIN_DETAILS_KEY, JSON.stringify(myDetails));
          authHelper.setMeShared(myDetails);
          authHelper.setLogin(result.data, false);

          return result;
        },
        function (result) {
          if (!authHelper.isPublicRoute()) {
            if ('abort' != result.xhrStatus) {
              redirectToBackendLoginPage();
            }
          }
          throw result;
        }
      );
    };

    authHelper.getUserType = function () {
      var login = authHelper.getLogin(true);
      if (login) {
        return login['user_type_name'];
      }
    };

    authHelper.getUserTypeName = function () {
      var myDetailsStr = sessionStorage.getItem(CONSTANTS.LOGIN_DETAILS_KEY);
      if (myDetailsStr) {
        var myDetails = JSON.parse(myDetailsStr);
        var userTypeName = myDetails.user_type_name;

        return new Promise(function(resolve, reject) {
          if (userTypeName) {
            resolve({'data': {'user_type_name': userTypeName}});
          }
          else {
            reject('Data not found for user type name');
          }
        });
      }
      else {
        return authHelper.isLoggedIn();
      }
    };

    authHelper.redirectLoggedIn = function (returnUrl, isUrlNormal) {
      var angular_path = '';

      if (returnUrl) {
        if (isUrlNormal) {
          angular_path = '#' + returnUrl;
        } else {
          angular_path = '#' + decodeURIComponent(returnUrl);
        }
      }

      authHelper.getUserTypeName().then(
        function (result) {
          var userType = result.data.user_type_name;
          $window.location.href = '../'+ userType +'/' + angular_path;
        }
      );
    };

    authHelper.getMeSharedOrig = function () {
      return shared_data['me_orig'];
    };

    authHelper.getMeShared = function () {
      return shared_data['me'];
    };

    authHelper.getUserPermissions = function (permission) {
      let myDetails = getMyDetails();
      if (Array.isArray(myDetails) && myDetails.length === 0) {
        return [];
      }

      let permissions = shared_data['me'].permissions;
      if (!permissions) {
        return [];
      }
      return permissions;
    };

    authHelper.setMeSharedOrig = function (data) {
      for (var k in data) {
        shared_data.me_orig[k] = data[k];
      }
    };

    authHelper.setMeShared = function (data, use_orig_login) {
      for (var k in data) {
        shared_data.me[k] = data[k];
      }
    };

    authHelper.isImpersonation = function () {
      return storageService.getLocalStorage()[IMPERSONATION_KEY] === IMPERSONATION_ACTIVE;
    };

    authHelper.setImpersonationData = function () {
      storageService.getLocalStorage()[IMPERSONATION_KEY] = IMPERSONATION_ACTIVE;
    };

    authHelper.restoreSharedDataInLocalStorage = function () {
      storageService.getLocalStorage()[IMPERSONATION_KEY] = IMPERSONATION_INACTIVE;
      const IS_ORIGINAL_USER = true;
      let origUserDetails = authHelper.getLogin(IS_ORIGINAL_USER);
      authHelper.setMeShared(authHelper.getLogin(origUserDetails));
      sessionStorage.setItem(CONSTANTS.LOGIN_DETAILS_KEY, JSON.stringify(origUserDetails));
    };

    authHelper.logout = function (redirectUrl) {
      sessionStorage.removeItem(CONSTANTS.LOGIN_DETAILS_KEY);
      storageService.getLocalStorage()[LOGIN_PREFIX_ORIG] = '';
      storageService.getLocalStorage()[LOGIN_PREFIX] = '';
      storageService.getLocalStorage()[IMPERSONATION_KEY] = IMPERSONATION_INACTIVE;
      sessionStorage.removeItem('admin_is_shortlist_mode');
      INTERCOM.initForAnonym();
      window.location.href = apiHelper.getApiUrl() + '/auth/logout/?next='
      + apiHelper.getApiUrl() + '/auth/login/?next=' + encodeURIComponent(redirectUrl);
    };

    authHelper.getLoginDetails = function () {
      return new Promise(function (resolve, reject) {
        var myDetailsStr = sessionStorage.getItem(CONSTANTS.LOGIN_DETAILS_KEY);
        if (myDetailsStr) {
          let myDetails = JSON.parse(myDetailsStr);
          authHelper.setMeShared(myDetails);
          return resolve(myDetails);
        }

        authHelper.isLoggedIn().then(
          function (result) {
            resolve(result.data);
          },
          reject
        );
      });
    };

    authHelper.doLogoutAll = function (addReturnUrl) {
      var returnUrl = $location.absUrl();
      if (!addReturnUrl) {
        returnUrl = $location.protocol() + '://' + $location.host();
        if ($location.port()) {
          returnUrl += (':' + $location.port() + '/#');
        }
      }
      authHelper.logout(returnUrl);
    };
  }]
);
