app.service('cacheHelper', ['$window', 'INTERCOM', 'storageService', 'apiHelper', '$http', '$location', 'CONSTANTS','$rootScope',
  function ($window, INTERCOM, storageService, apiHelper, $http, $location, CONSTANTS, $rootScope) {
    const cacheHelper = this;

    cacheHelper.setWithExpiry = function(key, value, ttl) {
      const item = {
        value: value,
        expiry: new Date().getTime() + ttl
      };
      localStorage.setItem(key, JSON.stringify(item));
    };

    cacheHelper.getWithExpiry = function(key) {
      const itemStr = localStorage.getItem(key);
      if (!itemStr) return null;
      const item = JSON.parse(itemStr);
      if (new Date().getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
      }
      return item.value;
    };

  }]);
