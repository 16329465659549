app.controller('openDocumentController',
  ['$scope', '$routeParams', 'apiHelper', 'portalHelper', 'authHelper',
    function ($scope, $routeParams, apiHelper, portalHelper, authHelper) {

      portalHelper.portalReady(function () {
        var file_rel_url = '/api/document/' + $routeParams.id + '.json';
        window.location.href = apiHelper.getApiUrl() + '/static/pdfjs/index.html' + '?file=' + file_rel_url;
      });

    }]
);
