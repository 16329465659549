app.service('defaultBreadcrumbs', ['$location', 'authHelper', '$rootScope',
  function ($location, authHelper, $rootScope) {
    var defaultBreadcrumbsService = this;

    defaultBreadcrumbsService.getBreadcrumb = function (start, extra_url_lookup, extra_text_lookup) {
      var ret = [];
      const stakeholder_title = $rootScope.nomenclature_dict.stakeholder_title;
      const stakeholders_title = $rootScope.nomenclature_dict.stakeholders_title;
      const stakeholder_lower = $rootScope.nomenclature_dict.stakeholder_lower;

      var url_lookup = {
        'New Disease Area': '#/Admin/DiseaseAreas/0',  
        'Edit Disease Area': '',  
        'Manage Disease Areas': '#/Admin/DiseaseAreas',

        'New Client FAQ': '#/Admin/ClientFAQ/0',
        'Edit Client FAQ': '', 
        'Manage Client FAQ\'s': '#/Admin/ClientFAQs',
        ['New ' + stakeholder_title + ' FAQ']: '#/Admin/PayerFAQ/0',
        ['Edit ' +  stakeholder_title + ' FAQ']: '', 
        ['Manage ' + stakeholder_title + ' FAQ\'s']: '#/Admin/PayerFAQs',

        'New Expertise': '#/Admin/Functional Expertise/0',  
        'Edit Expertise': '',  
        'Manage Expertise': '#/Admin/FunctionalExpertise',
        'Honorarium Payments': '#/Admin/HonorariumPayments',
        'Create Bills': '#/Admin/CreateBills',

        ['Edit ' + stakeholder_title]: '',
        ['View ' + stakeholder_title]: '#/Admin/Payer/View',
        [stakeholders_title]: '#/Admin/Payers',

        'Help': '',

        'System Administration': '#/Admin',
        'Open Surveys': '#/Admin/OpenSurveys',

        'Login': '#/Login',

        'My Account': '#/Payers/MyAccount',
        'New Surveys': '#/Payers/NewSurveys',
        'Surveys in Progress__payer': '#/Payers/ProgressSurveys',
        'My Profile': '#/Payers/MyProfile',
        'Edit My Profile': '#/Payers/EditProfile',
        [stakeholder_title + ' Referral']: '#/Payers/Referral',
        'My Expertise': '#/Payers/Expertise',

        'Dashboard__admin': '#/Administration',
        ['Dashboard__' + stakeholder_lower]: '#/Payers',
        'Dashboard__client': '#/Client',

        'Home': '#/Login',
        'Home__admin': '#/',
        'Home__client': '#/Client',
        ['Home__'+ stakeholder_lower]: '#/Payers',
        'Clarification Requests': '#/Payers/Clarifications',

        'Surveys in Development': '#/Client/InDevelopment',
        'Document Repository__client': '#/Client/DocumentRepository',
        'Surveys in Progress__client': '#/Client/InProgress',
        'Completed & Closed Surveys': '#/Client/CompletedSurveys',
        'Manage Scales__client': '#/Client/ManageScales',
        'Manage Rating Scales__client': '/Client/RatingScale/:id',
        'Manage Drag and Drop Ranking Scales__client': '/Client/DragDropRanking/:id',
        'Manage Radio Button Ranking Scales__client': '/Client/RadioRanking/:id',
        'Manage Disclaimer Templates': '#/Client/ManageDisclaimerTemplates',
        'Edit Disclaimer Template': '#/Client/Disclaimer/:id',
        'Clarification Requests & Responses': '#/Client/Clarifications',
        'Clone Surveys': '#/Client/CloneSurveys',

        'Forecasting': '#/Admin/Forecasting',
        'Document Repository__admin': '#/Admin/DocumentRepository',
        'Surveys in Development__admin': '#/Admin/SurveysInDevelopment',
        [stakeholder_title + ' Responses']: '#/Admin/ResponseApproval',
        'Export Responses': '#/Admin/ExportResponses/Survey/:id',
        'All Surveys': '#/Admin/AllSurveys',
        'Clarification requests for review': '#/Admin/ClarificationRequests',
        'Clarification responses for review': '#/Admin/ClarificationResponses',
        'Manage Clients and Client Users': '#/Admin/Clients',
        'Manage Genesis Research Users': '#/Admin/Users',
        'Manage Page Content': '#/Admin/PageContent',
        'Manage Question Bank': '#/Admin/QuestionBank',
        'Manage Licences': '#/Admin/Licences',
        'Upgrade Licence': '#/Admin/Licence/Upgrade/:id',
        'View Licence': '#/Admin/Licence/:id',
        'Live Surveys': '#/Admin/LiveSurveys',
        'Global Scales__admin': '#/Admin/GlobalScales',
        'Clone Disclaimer__admin': '#/Admin/CloneDisclaimer',
        'Global Disclaimers__admin': '#/Admin/GlobalDisclaimers',
        'Global Disclaimers Template Authoring__admin': '#/Admin/Disclaimer/:id',
        'Manage Rating Scales__admin': '/Admin/RatingScale/:id',
        'Manage Drag and Drop Ranking Scales__admin': '/Admin/DragDropRanking/:id',
        'Manage Radio Button Ranking Scales__admin': '/Admin/RadioRanking/:id',
        'AdminClone Surveys': '#/Admin/CloneSurveys',
        'Survey General Information': '#/Admin/Survey/:id/Project',
        [stakeholder_title + ' AndCountry']: '#/Admin/Survey/:id/PayerAndCountry',
        'Survey Licences': '#/Admin/Survey/:id/Licences',
        'Survey Disclaimers': '#/Admin/Survey/:id/Disclaimers',
        'Milestones': '#/Admin/Survey/:id/Milestones',
        'Questionnaires': '#/Admin/ManageQuestions/:id/:client_id',
        'Survey Notes': '#/Admin/Survey/:id/Notes',
        'Survey Client Folder': '#/Admin/Survey/:id/ClientFolder',
        'Manage Survey Respondents': '#/Admin/Survey/:id/Respondents',
        'Survey Respondents': '#/Admin/Survey/:id/Respondents',
        'Client Statement Report Options': '#/Admin/Reports/ClientStatementOptions',
        'Reports': '#/Admin/ReportsList',
        ['Advanced '+ stakeholder_title +' Selection']: '#Admin/AdvancedPayerSelection',
        'Expertise Detail': '#/Admin/Payer/Expertise/:id',
        'Survey Charges': '#/Admin/Survey/:id/Charges'
      };

      var parent_lookup = {
        'New Disease Area': 'Manage Disease Areas',
        'Edit Disease Area': 'Manage Disease Areas',
        ['Edit ' + stakeholders_title]: [stakeholders_title],
        ['View ' + stakeholder_title]: [stakeholders_title],
        [stakeholder_title + ' Statistics']: [stakeholders_title],
        'Manage Disease Areas': 'System Administration',
        [stakeholders_title]: 'System Administration',
        'Edit My Profile': 'My Profile',
        [stakeholder_title + ' Referral']: 'Dashboard',
        'My Expertise': 'Dashboard',

        'New Client FAQ': 'Manage Client FAQ\'s',
        'Edit Client FAQ': 'Manage Client FAQ\'s',
        'Manage Client FAQ\'s': 'System Administration',
        ['New ' + stakeholder_title + ' FAQ']: ['Manage ' + stakeholder_title + ' FAQ\'s'],
        ['Edit ' + stakeholder_title + ' FAQ']: ['Manage ' + stakeholder_title + ' FAQ\'s'],
        ['Manage ' + stakeholder_title + ' FAQ\'s']: 'System Administration',

        'New Expertise': 'Manage Expertise',
        'Edit Expertise': 'Manage Expertise',
        'Manage Expertise': 'System Administration',

        'Open Surveys': 'Dashboard',
        'Forecasting' : 'Dashboard',
        'Manage Survey Respondents': 'Open Surveys',

        'System Administration': 'Home',
        'Home': null,
        'Login': null,
        'Forgotten Password': 'Login',
        'Password Reset': 'Login',
        'Create First Password': 'Login',
        'Forgotten Username': 'Login',

        'FAQs': 'Home',
        ['FAQs__' + stakeholder_lower]: 'Dashboard',
        'FAQs__client': 'Dashboard',
        'FAQs__admin': 'Dashboard',

        'Disclaimer': 'New Surveys',
        'View Survey__progress': 'Surveys in Progress',
        'View Survey__new': 'New Surveys',
        'My Account': 'Dashboard',
        'Surveys in Progress': 'Dashboard',
        'Survey History': 'Dashboard',
        'New Surveys': 'Dashboard',
        'Setup TFA__login': 'Login',
        'Setup TFA': 'Change Password',
        'Dashboard': null,
        'Survey Already Submitted': 'Dashboard',
        'My Profile': 'Dashboard',

        'Help': 'Home',
        ['Help__' + stakeholder_lower]: 'Dashboard',
        'Help__client': 'Dashboard',
        'Help__admin': 'Dashboard',

        'Survey Submitted': 'Dashboard',
        'Clarification Requests': 'Dashboard',
        'View Survey__clarifications': 'Clarification Requests',

        'Welcome to Genesis Research Group': 'Dashboard',
        'Surveys in Development': 'Dashboard',
        'Completed & Closed Surveys': 'Dashboard',
        'Preview Survey__draft__client': 'Surveys in Development',
        'Preview Survey__submitted_for_approval__client': 'Surveys in Development',
        'Preview Survey__live__client': 'Surveys in Progress',
        ['Test Survey as a ' + stakeholder_title]: 'Surveys in Development',
        'Pilot Testing Successful': 'Surveys in Development',
        'Edit Survey - New': 'Dashboard',
        'Create Survey': 'Surveys in Development',
        'Survey Brief__client': 'Surveys in Development',
        'Edit Survey__client': 'Surveys in Development',
        'Configure Survey': 'Surveys in Development',
        'Reorder Questions': 'Edit Survey',
        'Edit Rating Scale__question': 'Edit Survey',
        'Edit Rating Scale__manage': 'Manage Scales',
        'Edit Ranking Scale__manage': 'Manage Scales',
        'Edit Drag and Drop Ranking__manage': 'Manage Scales',
        'Edit Drag and Drop Ranking__question': 'Edit Survey',
        'Edit Radio Button Ranking__question': 'Edit Survey',
        'Edit Table of Responses__question': 'Edit Survey',
        'Manage Scales__client': 'Dashboard',
        'Manage Rating Scales__client': 'Manage Scales',
        'Manage Drag and Drop Ranking Scales__client': 'Manage Scales',
        'Manage Radio Button Ranking Scales__client': 'Manage Scales',
        'Survey Deleted': 'Dashboard',
        'Survey Not Found': 'Dashboard',
        'Survey Sent For Approval': 'Dashboard',
        'Document Repository__client': 'Dashboard',
        'Client__Edit Document': 'Document Repository__client',
        'Edit Document__edit_questions': 'Edit Survey',
        'Edit Document__create_survey': 'Create Survey',
        'Edit Document__edit_survey': 'Edit Survey',
        'View Survey__responses': 'Surveys in Progress',
        'View Survey__responses_complete': 'Completed & Closed Surveys',
        'View Survey__responses_closed': 'Completed & Closed Surveys',
        'Clone Survey': 'Dashboard',
        'Clarification Requests & Responses': 'Dashboard',
        'View Survey__client_clarifications': 'Clarification Requests & Responses',
        'Manage Disclaimer Templates': 'Dashboard',
        'Manage Disclaimers__From_Survey': 'Edit Survey__client',
        'Edit Disclaimer__manage': 'Manage Disclaimer Templates',
        'Edit Disclaimer__survey': 'Configure Survey',
        'Manage Credit Allocation': 'Dashboard',
        'Clone Surveys': 'Dashboard',

        'Draft Projects': 'Dashboard',
        'Honorarium Payments': 'Dashboard',
        'Create Bills': 'Honorarium Payments',
        'Surveys for approval': 'Dashboard',
        [stakeholder_title + ' Responses']: 'Dashboard',
        'Expertise Detail': ['Manage ' + stakeholders_title],
        'All Surveys': 'Dashboard',
        'Live Surveys': 'Dashboard',
        'Reports': 'Dashboard',
        ['Advanced ' + stakeholder_title + ' Selection']: 'Dashboard',
        'Document Repository__admin': 'Dashboard',
        'Surveys in Development__admin': 'Dashboard',
        'Admin__Edit Document': 'Document Repository__admin',
        'View Survey__admin': 'Surveys for approval',
        'View Survey__all_responses_admin': 'All Surveys',
        'View Survey__all_responses_from_LiveSurveys': 'Live Surveys',
        'Project Brief__from_AllSurveys': 'All Surveys',
        'Draft Survey__from_AllSurveys': 'All Surveys',
        'Project Brief__from_DraftProjects': 'Draft Projects',
        'Draft Survey__from_DraftProjects': 'Draft Projects',
        'View Responses': [stakeholder_title + ' Responses'],
        'Response Approval': [stakeholder_title + ' Responses'],
        'View Responses__from_LiveSurveys': 'Live Surveys',
        'Clarification requests for review': 'Dashboard',
        'View Survey__clar_requests_for_review': 'Clarification requests for review',
        'Clarification responses for review': 'Dashboard',
        'View Survey__clar_responses': 'Clarification responses for review',
        'Report Details': 'All Surveys',
        'Report Details__from_LiveSurveys': 'Live Surveys',
        'Issues for review': 'Dashboard',
        ['Import ' + stakeholders_title]: [stakeholders_title],
        'Impersonate User': 'Dashboard',
        'Import Payment Information': 'System Administration',
        'Global Scales__admin': 'Dashboard',
        'Clone Disclaimer__admin': 'Dashboard',
        'Global Disclaimers__admin': 'Dashboard',
        'Global Disclaimers Template Authoring__admin': 'Global Disclaimers__admin',
        'Edit Disclaimer Template': 'Manage Disclaimer Templates',
        'Manage Rating Scales__admin': 'Global Scales',
        'Manage Drag and Drop Ranking Scales__admin': 'Global Scales',
        'Manage Radio Button Ranking Scales__admin': 'Global Scales',
        'Manage Clients and Client Users': 'System Administration',
        'Manage Genesis Research Users': 'System Administration',
        'Edit Client': 'Manage Clients and Client Users',
        'Edit Client__manage': 'Manage Clients and Client Users',
        'View Client': 'Manage Clients and Client Users',
        'Edit Client User': 'Manage Clients and Client Users',
        'Edit Client User__manage': 'Manage Clients and Client Users',
        'View Client User': 'Manage Clients and Client Users',
        'Edit Genesis Research User': 'Manage Genesis Research Users',
        'Manage Page Content': 'System Administration',
        'Edit Page': 'Manage Page Content',
        'Manage Question Bank': 'System Administration',
        'New Question': 'Manage Question Bank',
        'Edit Question': 'Manage Question Bank',
        'Edit Licence for': 'View Licence',
        'Upgrade Licence for': 'View Licence',
        'Adjustments': 'View Licence',
        'Transfer Credits': 'View Licence',
        'View Licence': 'Manage Licences',
        'Credit Allocation': 'Manage Licences',
        'Credit Allocation__view_client': 'View Client',
        'Credit Allocation__view_client_user': 'View Client User',
        'Manage Credits': 'Credit Allocation',
        'Login Attempts': 'System Administration',
        'Forgotten Password Attempts': 'System Administration',
        'Additional Fees': [stakeholders_title],
        'Questions from other Surveys': 'Dashboard',
        'Questions from other Surveys__Surveys_Edit': 'Edit Survey__admin',
        'Survey Brief__admin': 'Surveys in Development__admin',
        'Edit Survey__admin': 'Surveys in Development__admin',
        'Preview Survey__draft__admin': 'Surveys in Development__admin',
        'Preview Survey__submitted_for_approval__admin': 'Surveys in Development__admin',
        'Survey General Information': 'Surveys in Development__admin',
        [stakeholder_title + ' And Country']: 'Surveys in Development__admin',
        'Survey Licences': 'Surveys in Development__admin',
        'Survey Disclaimers': 'Surveys in Development__admin',
        'Milestones': 'Surveys in Development__admin',
        'Survey Charges': 'Surveys in Development__admin',
        'Questionnaires': 'Surveys in Development__admin',
        'Preview Survey': 'Surveys in Development__admin',
        'Survey Notes': 'Surveys in Development__admin',
        'Survey Client Folder': 'Surveys in Development__admin',
        'Survey Cancellation': 'Surveys in Development__admin',
        'Survey Respondents': 'Open Surveys',
        'Responses': 'Open Surveys',
        'Client Statement Report Options': 'Reports'
      };

      var user_type_name = authHelper.getUserType();

      for (var current = start; current != null; current = parent_lookup[current]) {
        var current2 = current + '__' + user_type_name;

        if (current2 in parent_lookup) {
          current = current2;
        }

        var url = (current2 in url_lookup) ? url_lookup[current2] : url_lookup[current];

        if (!url && extra_url_lookup && current in extra_url_lookup) {
          url = extra_url_lookup[current];
        }

        var item_text = current.split('__')[0];

        if (extra_text_lookup && current in extra_text_lookup) {
          item_text = item_text + ' - ' + extra_text_lookup[current];
        }

        if (current === start) {
          url = '#' + $location.path();
        }

        ret.unshift([item_text, url]);
      }

      return ret;
    };
  }]
);
