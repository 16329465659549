app.directive('completedSurveyPanel', ['portalHelper', 'CONSTANTS',
  function (portalHelper, CONSTANTS) {
    return {
      scope: {
        survey: '='
      },
      templateUrl: portalHelper.getUrlRev('html_templates/directives/completed_survey_panel.435af13e.html'),
      controller: function ($scope) {
        $scope.getFileIconPath = portalHelper.getFileIconPath;
        $scope.CONSTANTS = CONSTANTS;

        $scope.download_report = function (report_id) {
          portalHelper.downloadFileWithUrl(
            '/api/surveyreport/' + report_id + '.json'
          );
        };

        $scope.is_footer_hidden = function() {
          return $scope.survey.status == CONSTANTS.surveyStatuses.CANCELLED;
        };
      }
    };
  }
]);
