app.service('tor2Service', ['$rootScope',
  function ($rootScope) {
    var service = this;

    var TYPE_OF_CELL = {
      COLUMN_HEADER: 'columnheader',
      COLUMN_SUB_HEADER: 'columnsubheader',
      ROW_HEADER: 'rowheader',
      LABEL: 'label',
      TEXT_BOX: 'textboxquestion',
      CHECK_BOX: 'checkboxquestion',
      DROP_DOWN: 'dropdownquestion',
      SEPARATOR: 'separator'
    };
    service.TYPE_OF_CELL = TYPE_OF_CELL;

    var MANDATORY_ENUM = {
      NONE: 'none',
      AT_LEAST_ONE: 'at_least_one',
      ALL: 'all'
    };
    service.MANDATORY_ENUM = MANDATORY_ENUM;

    var HEADER_WIDTH_UI_TYPE_ENUM = {
      PERCENT: '%',
      PIXEL: 'px'
    };

    var HEADER_WIDTH_API_TYPE_ENUM = {
      PERCENT: 'percent',
      PIXEL: 'pixels'
    };

    service.HEADER_WIDTH_UI_TYPE_ENUM = HEADER_WIDTH_UI_TYPE_ENUM;

    var CELL_PROPS = {
      ROW: 'row',
      COL: 'col',
      TYPE: 'type',
      CONTENT_MARKDOWN: 'content_markdown',
      DISABLE_ROW_REMAINDER_IF_UNCHECKED: 'disable_row_remainder_if_unchecked',
      OPTIONS: 'options',
      MANDATORY: 'mandatory',
      MULTI_SELECT: 'multi_select',
      SPAN_WHOLE_ROW: 'span_whole_row',
      SINGLE_LINE: 'single_line',
      SELECTED: 'selected',
      NUMERIC: 'numeric',
      MAX_LENGTH: 'max_length',
      PLACEHOLDER: 'placeholder',
      WIDTH: 'width',
      WIDTH_PROPS: {
        AMOUNT: 'amount',
        UNIT: 'unit'
      },
      HIDE_COLUMN: 'hide_column'
    };
    service.CELL_PROPS = CELL_PROPS;

    var COPY_OPTIONS_ENUM = {
      TEXT_AND_OPTIONS: 'text_and_options',
      TEXT_ONLY: 'text_only',
      OPTIONS_ONLY: 'options_only'
    };
    service.COPY_OPTIONS_ENUM = COPY_OPTIONS_ENUM;

    var CONFIG_KEY = 'config';
    service.CONFIG_KEY = 'config';

    var CONFIG_PROPS = {
      HIDE_FIRST_COL: 'hide_first_col'
    };
    service.CONFIG_PROPS = CONFIG_PROPS;

    var convertHeaderWidthEnumForAPI = function (uiEnum) {
      return uiEnum === HEADER_WIDTH_UI_TYPE_ENUM.PERCENT
        ? HEADER_WIDTH_API_TYPE_ENUM.PERCENT
        : HEADER_WIDTH_API_TYPE_ENUM.PIXEL;
    };

    var convertHeaderWidthEnumForUI = function (apiEnum) {
      return apiEnum === HEADER_WIDTH_API_TYPE_ENUM.PERCENT
        ? HEADER_WIDTH_UI_TYPE_ENUM.PERCENT
        : HEADER_WIDTH_UI_TYPE_ENUM.PIXEL;
    };

    var defaultIfUndefined = function (value, defaultValue) {
      return value ? value : defaultValue;
    };

    var fetchDefaultMandatoryValueFor = function (typeOfCell) {
      var booleanValues = [
        TYPE_OF_CELL.TEXT_BOX, TYPE_OF_CELL.DROP_DOWN
      ];

      if (booleanValues.indexOf(typeOfCell) > -1) {
        return false;
      }

      if (typeOfCell === TYPE_OF_CELL.ROW_HEADER) {
        return MANDATORY_ENUM.NONE;
      }

      return null;
    };

    var createDefaultCell = function (typeOfCell, contentMarkdown, row, col) {
      var cell = {};
      cell[CELL_PROPS.ROW] = row == null ? 0 : row;
      cell[CELL_PROPS.COL] = col == null ? 0 : col;
      cell[CELL_PROPS.TYPE] = typeOfCell;
      cell[CELL_PROPS.CONTENT_MARKDOWN] = contentMarkdown == null ? '' : contentMarkdown;
      cell[CELL_PROPS.DISABLE_ROW_REMAINDER_IF_UNCHECKED] = false;
      cell[CELL_PROPS.OPTIONS] = null;
      cell[CELL_PROPS.MANDATORY] = fetchDefaultMandatoryValueFor(typeOfCell);
      cell[CELL_PROPS.MULTI_SELECT] = false;
      cell[CELL_PROPS.SPAN_WHOLE_ROW] = false;

      return cell;
    };

    var createNewRowHeaderCell = function (cellContent, mandatory, rowNumber) {
      var cell = createDefaultCell(TYPE_OF_CELL.ROW_HEADER, cellContent, rowNumber, 0);
      cell[CELL_PROPS.MANDATORY] = mandatory == null ? MANDATORY_ENUM.NONE : mandatory;
      cell[CELL_PROPS.WIDTH] = null;
      return cell;
    };

    service.create_new_label_cell = function (cellContent, rowNumber, colNumber) {
      return  createDefaultCell(TYPE_OF_CELL.LABEL, cellContent, rowNumber, colNumber);
    };

    service.create_header_cell = function (column, isSubHeader) {
      var cell = {
        type: isSubHeader ? TYPE_OF_CELL.COLUMN_SUB_HEADER : TYPE_OF_CELL.COLUMN_HEADER,
        content_markdown: (isSubHeader ? 'Sub ' : '') + 'Header Cell',
        col: column,
        row: 0,
        hide_column: false
      };

      if (isSubHeader) {
        cell.is_sub_header_cell = true;
      } else {
        cell.is_header_cell = true;
        cell.width = null;
      }

      return cell;
    };

    service.create_column_header_row = function (isSubHeaderRow, existingRow, colCount) {
      var row = [];
      for (var i=0; i<colCount; i++) {
        if (existingRow != null && existingRow[i] != null) {
          row.push(existingRow[i]);
        } else {
          row.push(service.create_header_cell(i, isSubHeaderRow));
        }
      }

      return row;
    };

    service.create_new_default_row = function (rowNumber, colCount) {
      var row = [];

      for (var i=0; i<colCount; i++) {
        if (i === 0) {
          row.push(createNewRowHeaderCell('Row', MANDATORY_ENUM.ALL, rowNumber));
        } else {
          row.push(service.create_new_label_cell('Label Cell', rowNumber, i));
        }
      }

      return row;
    };

    service.validate_tor_data = function (data, randomize_options) {
      var result = [];

      if (data == null || data.length === 0 || data[0].length === 0) {
        result.push('No TOR data specified.');
        return result;
      }

      var errCount = 1;

      for (var i=0; i<data.length; i++) {
        var row = data[i];

        if (randomize_options && row[0].type == TYPE_OF_CELL.SEPARATOR) {
          result.push(errCount + '. Separator Rows are not allowed with randomize options');
          errCount++;
        }

        for (var j=0; j<row.length; j++) {
          var cell = row[j];

          if (cell[CELL_PROPS.TYPE] === TYPE_OF_CELL.DROP_DOWN &&
            (cell[CELL_PROPS.OPTIONS] == null ||
            cell[CELL_PROPS.OPTIONS].length < 2)) {

            var message = errCount + '. A dropdown cell has fewer than 2 options. Please add at least 2 options in dropdowns to continue.';
            if (data[0][j][CELL_PROPS.TYPE] === TYPE_OF_CELL.COLUMN_HEADER) {
              var colHeaderText = data[0][j][CELL_PROPS.CONTENT_MARKDOWN];
              var rowHeaderText = row[0][CELL_PROPS.CONTENT_MARKDOWN];

              message = errCount + '. The dropdown at Row: "' + rowHeaderText + '" and Column: "' + colHeaderText +
              '" has fewer than 2 options. Please add at least 2 options to continue.';
            }

            errCount++;
            result.push(message);
          }
        }
      }

      var cellTypesToIgnore = [
        TYPE_OF_CELL.SEPARATOR,
        TYPE_OF_CELL.COLUMN_SUB_HEADER,
        TYPE_OF_CELL.COLUMN_HEADER
      ];

      for (i=1; i<data.length; i++) {
        row = data[i];
        var firstCell = row[0];

        if (cellTypesToIgnore.indexOf(firstCell[CELL_PROPS.TYPE]) > -1) {
          continue;
        }

        if (firstCell[CELL_PROPS.MANDATORY] !== MANDATORY_ENUM.NONE) {
          var inputsPresent = false;
          for (j=1; j<row.length; j++) {
            cell = row[j];
            if (cell[CELL_PROPS.TYPE] === TYPE_OF_CELL.TEXT_BOX || cell[CELL_PROPS.TYPE] === TYPE_OF_CELL.DROP_DOWN) {
              inputsPresent = true;
              break;
            }
          }

          if (!inputsPresent) {
            message = errCount + '. No input elements (text or dropdown) were found on Row ' + i + '. Please add input elements or allow' + $rootScope.nomenclature_dict.stakeholders_lower  + ' to leave the row empty.';
            result.push(message);
            errCount++;
          }
        }
      }

      return result;
    };

    var processSeparatorCellForApi = function (cell) {
      var result = {};
      result[CELL_PROPS.TYPE] = TYPE_OF_CELL.SEPARATOR;
      result[CELL_PROPS.CONTENT_MARKDOWN] = defaultIfUndefined(cell[CELL_PROPS.CONTENT_MARKDOWN], '');
      result[CELL_PROPS.SPAN_WHOLE_ROW] = defaultIfUndefined(cell[CELL_PROPS.SPAN_WHOLE_ROW], false);

      return result;
    };

    var processSeparatorRowForApi = function (row) {
      var separatorRow = [];

      if (row[0][CELL_PROPS.SPAN_WHOLE_ROW] === true) {
        var firstCell = processSeparatorCellForApi(row[0]);

        for (var i=0; i<row.length; i++) {
          separatorRow.push(angular.copy(firstCell));
        }
      } else {
        for (i=0; i<row.length; i++) {
          var processedCell = processSeparatorCellForApi(row[i]);
          separatorRow.push(processedCell);
        }
      }

      return separatorRow;
    };

    service.fetch_ui_column_header_data = function (data, hideFirstCol) {
      if (data == null || data.length === 0 || data[0] == null || data[0].length === 0) {
        return null;
      }

      if (data[0][0][CELL_PROPS.TYPE] === TYPE_OF_CELL.COLUMN_HEADER) {
        var numOfColumns = data[0].length;
        var row = service.create_column_header_row(false, null, numOfColumns);

        if (hideFirstCol) {
          row[0][CELL_PROPS.HIDE_COLUMN] = hideFirstCol;
        }

        for (var i=0; i<numOfColumns; i++) {
          if (data[0][i][CELL_PROPS.CONTENT_MARKDOWN] != null) {
            row[i][CELL_PROPS.CONTENT_MARKDOWN] = data[0][i][CELL_PROPS.CONTENT_MARKDOWN];
          }

          if (data[0][i][CELL_PROPS.WIDTH] != null) {
            var widthProp = {};
            widthProp[CELL_PROPS.WIDTH_PROPS.UNIT] = convertHeaderWidthEnumForUI(data[0][i][CELL_PROPS.WIDTH][CELL_PROPS.WIDTH_PROPS.UNIT]);
            widthProp[CELL_PROPS.WIDTH_PROPS.AMOUNT] = parseInt(data[0][i][CELL_PROPS.WIDTH][CELL_PROPS.WIDTH_PROPS.AMOUNT]);

            row[i][CELL_PROPS.WIDTH] = widthProp;
          }
        }

        return row;
      }

      return null;
    };

    service.fetch_ui_column_sub_header_data = function (data) {
      if (data == null || data.length < 2 || data[1] == null || data[1].length === 0) {
        return null;
      }

      if (data[1][0][CELL_PROPS.TYPE] === TYPE_OF_CELL.COLUMN_SUB_HEADER) {
        var numOfColumns = data[1].length;
        var row = service.create_column_header_row(true, null, numOfColumns);

        for (var i=0; i<numOfColumns; i++) {
          if (data[1][i][CELL_PROPS.CONTENT_MARKDOWN] != null) {
            row[i][CELL_PROPS.CONTENT_MARKDOWN] = data[1][i][CELL_PROPS.CONTENT_MARKDOWN];
          }
        }

        return row;
      }

      return null;
    };

    var processLabelCell = function (baseData, cell) {
      var result = angular.copy(baseData);
      result[CELL_PROPS.TYPE] = TYPE_OF_CELL.LABEL;
      result[CELL_PROPS.CONTENT_MARKDOWN] = cell[CELL_PROPS.CONTENT_MARKDOWN];
      return result;
    };

    var processTextBoxCell = function (baseData, cell) {
      var result = angular.copy(baseData);
      result[CELL_PROPS.TYPE] = TYPE_OF_CELL.TEXT_BOX;
      result[CELL_PROPS.MANDATORY] = defaultIfUndefined(cell[CELL_PROPS.MANDATORY], false);
      result[CELL_PROPS.PLACEHOLDER] = defaultIfUndefined(cell[CELL_PROPS.PLACEHOLDER], '');
      result[CELL_PROPS.SINGLE_LINE] = defaultIfUndefined(cell[CELL_PROPS.SINGLE_LINE], false);
      result[CELL_PROPS.NUMERIC] = defaultIfUndefined(cell[CELL_PROPS.NUMERIC], false);
      result[CELL_PROPS.MAX_LENGTH] = defaultIfUndefined(cell[CELL_PROPS.MAX_LENGTH], null);

      return result;
    };

    var processRowHeaderCell = function (baseData, cell) {
      var result = angular.copy(baseData);
      result[CELL_PROPS.TYPE] = TYPE_OF_CELL.ROW_HEADER;
      result[CELL_PROPS.CONTENT_MARKDOWN] = defaultIfUndefined(cell[CELL_PROPS.CONTENT_MARKDOWN], '');
      result[CELL_PROPS.MANDATORY] = defaultIfUndefined(cell[CELL_PROPS.MANDATORY], MANDATORY_ENUM.ALL);

      return result;
    };

    var processSeparatorCellForUI = function (baseData, cell) {
      var result = angular.copy(baseData);
      result[CELL_PROPS.CONTENT_MARKDOWN] = defaultIfUndefined(cell[CELL_PROPS.CONTENT_MARKDOWN], '');
      result[CELL_PROPS.TYPE] = TYPE_OF_CELL.SEPARATOR;
      result[CELL_PROPS.SPAN_WHOLE_ROW] = defaultIfUndefined(cell[CELL_PROPS.SPAN_WHOLE_ROW], false);

      return result;
    };

    var processDropdownCell = function (baseData, cell) {
      var result = angular.copy(baseData);
      result[CELL_PROPS.TYPE] = TYPE_OF_CELL.DROP_DOWN;
      result[CELL_PROPS.MANDATORY] = defaultIfUndefined(cell[CELL_PROPS.MANDATORY], false);
      result[CELL_PROPS.MULTI_SELECT] = defaultIfUndefined(cell[CELL_PROPS.MULTI_SELECT], false);
      result[CELL_PROPS.OPTIONS] = angular.copy(defaultIfUndefined(cell[CELL_PROPS.OPTIONS], []));

      return result;
    };

    var processCheckboxCell = function (baseData, cell) {
      var result = angular.copy(baseData);
      result[CELL_PROPS.TYPE] = TYPE_OF_CELL.CHECK_BOX;
      result[CELL_PROPS.DISABLE_ROW_REMAINDER_IF_UNCHECKED]= defaultIfUndefined(cell[CELL_PROPS.DISABLE_ROW_REMAINDER_IF_UNCHECKED], false);

      return result;
    };

    var processForUI = function (cell, row, col) {
      switch (cell[CELL_PROPS.TYPE]) {
        case TYPE_OF_CELL.LABEL:
          return processLabelCell(
            createDefaultCell(TYPE_OF_CELL.LABEL, null, row, col),
            cell);

        case TYPE_OF_CELL.TEXT_BOX:
          return processTextBoxCell(
            createDefaultCell(TYPE_OF_CELL.TEXT_BOX, null, row, col),
            cell);

        case TYPE_OF_CELL.ROW_HEADER:
          return processRowHeaderCell(
            createDefaultCell(TYPE_OF_CELL.ROW_HEADER, cell[CELL_PROPS.CONTENT_MARKDOWN], row, col),
            cell);

        case TYPE_OF_CELL.SEPARATOR:
          return processSeparatorCellForUI(
            createDefaultCell(TYPE_OF_CELL.SEPARATOR, null, row, col),
            cell);

        case TYPE_OF_CELL.DROP_DOWN:
          return processDropdownCell(
            createDefaultCell(TYPE_OF_CELL.DROP_DOWN, null, row, col),
            cell);

        case TYPE_OF_CELL.CHECK_BOX:
          return processCheckboxCell(
            createDefaultCell(TYPE_OF_CELL.CHECK_BOX, null, row, col),
            cell);
      }
    };

    var processTorBodyCellForApi = function (cell) {
      switch (cell[CELL_PROPS.TYPE]) {
        case TYPE_OF_CELL.ROW_HEADER:
          return processRowHeaderCell({}, cell);

        case TYPE_OF_CELL.LABEL:
          return processLabelCell({}, cell);

        case TYPE_OF_CELL.CHECK_BOX:
          return processCheckboxCell({}, cell);

        case TYPE_OF_CELL.TEXT_BOX:
          return processTextBoxCell({}, cell);

        case TYPE_OF_CELL.DROP_DOWN:
          return processDropdownCell({}, cell);
      }
    };

    service.fetch_ui_tor_body_data = function (data) {
      if (data == null || data.length === 0 || data[0] == null || data[0].length === 0) {
        return null;
      }

      var cellTypesToSkip = [ TYPE_OF_CELL.COLUMN_HEADER, TYPE_OF_CELL.COLUMN_SUB_HEADER ];
      var torBody = [];
      var rowNum = 0;

      for (var i=0; i<data.length; i++) {
        if (data[i].length > 0 && data[i] != null) {
          if (cellTypesToSkip.indexOf(data[i][0][CELL_PROPS.TYPE]) > -1) {
            continue;
          }
        }

        var row = [];
        for (var j=0; j<data[i].length; j++) {
          row.push(processForUI(data[i][j], rowNum, j));
        }

        torBody.push(row);
        rowNum++;
      }

      return torBody;
    };

    var processHeaderCellForApi = function (cell) {
      var result = {};
      result[CELL_PROPS.TYPE] = TYPE_OF_CELL.COLUMN_HEADER;
      result[CELL_PROPS.CONTENT_MARKDOWN] = defaultIfUndefined(cell[CELL_PROPS.CONTENT_MARKDOWN], '');

      if (cell[CELL_PROPS.WIDTH] == null) {
        result[CELL_PROPS.WIDTH] = null;
      } else {
        var widthProp = {};
        widthProp[CELL_PROPS.WIDTH_PROPS.AMOUNT] = parseInt(cell[CELL_PROPS.WIDTH][CELL_PROPS.WIDTH_PROPS.AMOUNT]);
        widthProp[CELL_PROPS.WIDTH_PROPS.UNIT] = convertHeaderWidthEnumForAPI(cell[CELL_PROPS.WIDTH][CELL_PROPS.WIDTH_PROPS.UNIT]);
        result[CELL_PROPS.WIDTH] = widthProp;
      }

      return result;
    };

    var processSubHeaderCellForApi = function (cell) {
      var result = {};
      result[CELL_PROPS.TYPE] = TYPE_OF_CELL.COLUMN_SUB_HEADER;
      result[CELL_PROPS.CONTENT_MARKDOWN] = defaultIfUndefined(cell[CELL_PROPS.CONTENT_MARKDOWN], '');

      return result;
    };

    service.create_data_for_api = function (headerData, subHeaderData, bodyData) {
      let result = [];

      if (headerData != null) {
        let headerRow = [];
        for (var i = 0; i < headerData.length; i++) {
          headerRow.push(processHeaderCellForApi(headerData[i]));
        }

        result.push(headerRow);
      }

      if (subHeaderData != null) {
        let subHeaderRow = [];
        for (i = 0; i < subHeaderData.length; i++) {
          subHeaderRow.push(processSubHeaderCellForApi(subHeaderData[i]));
        }
        result.push(subHeaderRow);
      }

      for (let r = 0; r < bodyData.length; r++) {
        let row = bodyData[r];

        if (row[0][CELL_PROPS.TYPE] === TYPE_OF_CELL.SEPARATOR) {
          result.push(processSeparatorRowForApi(row));
        } else {
          let processedRow = [];
          for (let c = 0; c < row.length; c++) {
            processedRow.push(processTorBodyCellForApi(bodyData[r][c]));
          }
          result.push(processedRow);
        }
      }

      const tor2Data = {
        cells: result
      };

      if (headerData[0] && headerData[0][CELL_PROPS.HIDE_COLUMN] === true) {
        tor2Data[CONFIG_KEY] = {};
        tor2Data[CONFIG_KEY][CONFIG_PROPS.HIDE_FIRST_COL] = true;
      }

      return tor2Data;
    };

    service.get_cell_style = function (cell) {
      if (cell[CELL_PROPS.HIDE_COLUMN] === true) {
        return 'width: 30px';
      }

      if (cell[CELL_PROPS.WIDTH] != null) {
        return 'width: ' + cell[CELL_PROPS.WIDTH][CELL_PROPS.WIDTH_PROPS.AMOUNT] +
          cell[CELL_PROPS.WIDTH][CELL_PROPS.WIDTH_PROPS.UNIT];
      }

      return '';
    };

    service.fetch_col_span = function (row) {
      if (row[0][CELL_PROPS.TYPE] !== TYPE_OF_CELL.SEPARATOR) {
        return 1;
      }

      return row[0][CELL_PROPS.SPAN_WHOLE_ROW] === true ? row.length : 1;
    };

    service.fetch_cells_from_row = function (row) {
      if (row[0][CELL_PROPS.TYPE] !== TYPE_OF_CELL.SEPARATOR) {
        return row;
      }

      return row[0][CELL_PROPS.SPAN_WHOLE_ROW] === true ? [row[0]] : row;
    };

    service.is_label_type_cell = function (typeOfCell) {
      return [
        TYPE_OF_CELL.LABEL, TYPE_OF_CELL.ROW_HEADER,
        TYPE_OF_CELL.COLUMN_HEADER,
        TYPE_OF_CELL.COLUMN_SUB_HEADER
      ].indexOf(typeOfCell) > -1;
    };

    service.is_single_line_textbox = function (cell) {
      if (cell == null) {
        return false;
      }

      return cell[CELL_PROPS.TYPE] === TYPE_OF_CELL.TEXT_BOX &&
        cell[CELL_PROPS.SINGLE_LINE] === true;
    };

    service.is_multi_line_textbox = function (cell) {
      if (cell == null) {
        return false;
      }

      return cell[CELL_PROPS.TYPE] === TYPE_OF_CELL.TEXT_BOX &&
        cell[CELL_PROPS.SINGLE_LINE] !== true;
    };

    service.is_single_select_dropdown = function (cell) {
      if (cell == null) {
        return false;
      }

      return cell[CELL_PROPS.TYPE] === TYPE_OF_CELL.DROP_DOWN &&
        cell[CELL_PROPS.MULTI_SELECT] !== true;
    };

    service.is_multi_select_dropdown = function (cell) {
      if (cell == null) {
        return false;
      }

      return cell[CELL_PROPS.TYPE] === TYPE_OF_CELL.DROP_DOWN &&
        cell[CELL_PROPS.MULTI_SELECT] === true;
    };

    service.is_separator_cell = function (cellType) {
      return cellType === TYPE_OF_CELL.SEPARATOR;
    };
  }]);
