app.controller('appController',
  ['$rootScope', '$scope', '$location', '$sanitize', 'authHelper', 'SETTINGS', 'INTERCOM',
    'portalHelper', 'CONSTANTS', '$interval', 'apiHelper', '$window', '$timeout',
    '$ngConfirm', 'storageService', 'persistentService', 'historyService', '$document',
    'maintenanceService', 'permissionsHelper', 'HttpAuth', 'cacheHelper',
    function ($rootScope, $scope, $location, $sanitize, authHelper, SETTINGS, INTERCOM,
      portalHelper, CONSTANTS, $interval, apiHelper, $window, $timeout,
      $ngConfirm, storageService, persistentService, historyService, $document,
      maintenanceService, permissionsHelper, HttpAuth, cacheHelper) {

      $scope.authHelper = authHelper;
      $scope.is_impersonation = authHelper.isImpersonation();
      $scope.login_orig = authHelper.getLogin(true);
      $scope.login = authHelper.getLogin(false);
      $scope.shared_data = authHelper.getSharedData();
      $scope.inside_popup = $location.search().insidePopup;

      maintenanceService.pollMaintenanceStatus();

      $rootScope.cleanText = function (text) {
        return $sanitize(text);
      };

      $scope.go_to_password_change = function () {
        window.location.href = apiHelper.getApiUrl() + '/auth/change-password/';
      };

      window.addEventListener('beforeunload', authHelper.removeStaleLoginDetails);

      $rootScope.title_prefix = portalHelper.fix_undefined(SETTINGS.TITLE_PREFIX);
      $rootScope.title = portalHelper.fix_undefined(SETTINGS.TITLE_PREFIX) + ' Genesis Research Group';
      $rootScope.usersnap = portalHelper.fix_undefined(SETTINGS.USERSNAP);
      $scope.getUrl = portalHelper.getUrl;
      $scope.isLocalStorageSupported = storageService.isLocalStorageSupported;

      $rootScope.CONSTANTS = CONSTANTS;

      $rootScope.$on('$routeChangeSuccess', function(event, current, previous) {
        historyService.add_to_history(angular.copy($window.location.href), '');
      });

      $scope.is_print_preview = function () {
        return $location.path().indexOf('Preview/') !== -1;
      };

      $scope.go_to_auth_account = function () {
        window.location.href = apiHelper.getApiUrl() + '/auth/account/';
      };

      $scope.is_test_survey = function () {
        return $location.path().indexOf('TestSurvey/') !== -1;
      };

      $scope.hide_menu_bar = function () {
        return $rootScope.hide_menu_bar;
      };

      $scope.showImpersonationBar = function () {
        if (authHelper.isImpersonation()) {
          $('#impersonation-banner').slideDown();
        }
      };

      var is_login_page = $window.location.pathname.indexOf('/login/') === 0;
      var is_impersonation = authHelper.isImpersonation();

      INTERCOM.initForAnonym();

      var IDLE_TIMEOUT = 30 * 60 * 1000; 
      var COUNTDOWN_TIMEOUT = 120; 
      var INTERVAL_IN_SECONDS = 1; 

      $scope.timeout_idle = null;

      function cancelCountdown() {
        if ($scope.countdown_timer) {
          $interval.cancel($scope.countdown_timer);
          $scope.countdown_timer = null;
        }
      }

      function resetIdleTimeout(seconds_since_last_move) {
        if ($scope.timeout_idle) {
          $timeout.cancel($scope.timeout_idle);
        }

        if (seconds_since_last_move) {
          $scope.timeout_idle = $timeout(timeoutIdleExpired,
            ((IDLE_TIMEOUT / 1000) - seconds_since_last_move) * 1000);
        } else {
          $scope.timeout_idle = $timeout(timeoutIdleExpired, IDLE_TIMEOUT);
        }
      }

      function showCountdownAndLogout() {
        $scope.countdown_initial = COUNTDOWN_TIMEOUT;
        $scope.countdown = $scope.countdown_initial;

        $scope.countdown_timer = $interval(function () {
          $scope.countdown = $scope.countdown - INTERVAL_IN_SECONDS;

          var last_click_ok = persistentService.getLocalStorageObject('idle_last_click_ok');
          var seconds_since_last_ok = null;
          if (last_click_ok) {
            last_click_ok = new Date(parseInt(last_click_ok, 10));
            seconds_since_last_ok = (new Date().getTime() - last_click_ok.getTime()) / 1000;
          }

          if (seconds_since_last_ok && seconds_since_last_ok < COUNTDOWN_TIMEOUT) {
            if ($scope.idlePopup) {
              $scope.idlePopup.close();
              $scope.idlePopup = null;
            }
            cancelCountdown();
            resetIdleTimeout();
            return;
          }

          var myDetails = portalHelper.getMyDetails();
          if ($scope.countdown <= 0 || !myDetails) {
            cancelCountdown();
            authHelper.doLogoutAll(true);
          }
        }, (INTERVAL_IN_SECONDS * 1000) );

        $scope.idlePopup = $ngConfirm({
          'title': 'You\'re Idle.',
          'contentUrl': '../' + 'html_templates/dialogs/idle_popup.565782b6.html',
          'type': 'orange',
          'scope': $scope,
          'buttons': {
            'OK': {
              btnClass: 'mat ui primary button',
              action: function (scope, button) {
                cancelCountdown();
                resetIdleTimeout();

                persistentService.saveLocalStorageObject(
                  '' + new Date().getTime(),
                  'idle_last_click_ok'
                );
              }
            }
          }
        });
      }

      function timeoutIdleExpired() {
        var last_move_time = persistentService.getLocalStorageObject('idle_last_move');
        var seconds_since_last_move = null;

        if (last_move_time) {
          last_move_time = new Date(parseInt(last_move_time, 10));
          seconds_since_last_move = (new Date().getTime() - last_move_time.getTime()) / 1000;
        }

        if (seconds_since_last_move && seconds_since_last_move < IDLE_TIMEOUT / 1000) {
          resetIdleTimeout(seconds_since_last_move);
          return;
        }

        if (!$scope.idle_countdown_timer) {
          showCountdownAndLogout();
        }
      }

      var last_recorded_time = new Date();

      function mouseMoveRecord(e) {
        if ((new Date().getTime() - last_recorded_time.getTime()) / 1000 > 1) {
          last_recorded_time = new Date();

          persistentService.saveLocalStorageObject(
            '' + last_recorded_time.getTime(),
            'idle_last_move'
          );
        }
      }
      $scope.is_logged_in = function() {
        return sessionStorage.getItem(CONSTANTS.LOGIN_DETAILS_KEY);
      };

      $rootScope.PERMISSIONS = CONSTANTS.getPermissions();
      $rootScope.has_permission = permissionsHelper.hasPermission;
      $rootScope.has_any_of_permissions = permissionsHelper.hasAnyOfPermissions;
      $rootScope.has_all_of_permissions = permissionsHelper.hasAllOfPermissions;


      authHelper.isLoggedIn().then(
        function (result)  {
          var myDetails = result.data;
          var is_logged_in = myDetails ? true: false;

          if (is_logged_in && !is_login_page) {
            INTERCOM.initForUser(myDetails, authHelper.getUserType());
          }

          HttpAuth.get(apiHelper.getApiUrl() + '/api/nomenclature_dictionary.json', null, true)
            .then(function(result) {
              $rootScope.nomenclature_dict = result.data;
            })
            .catch(portalHelper.showErrorCommon);


          portalHelper.portalReady(function () {
            persistentService.ready(function () {
              $scope.timeout_idle = $timeout(timeoutIdleExpired, IDLE_TIMEOUT);
              var bodyElement = angular.element($document);

              angular.forEach(['keydown', 'keyup', 'click', 'mousemove', 'DOMMouseScroll', 'mousewheel',
                'mousedown', 'touchstart', 'touchmove', 'scroll', 'focus'], function (EventName) {
                bodyElement.bind(EventName, function (e) {
                  mouseMoveRecord(e);
                });
              });
            });
          });
        },
        function () {
        }
      );
    }]
);
