
app.service('payerExpertiseService', [
  'portalHelper', '$ngConfirm', '$location', '$rootScope', 'HttpAuth', 'apiHelper', 'CONSTANTS',
  'menuHeaderService', 'payerHelper', 'permissionsHelper',
  function (portalHelper, $ngConfirm, $location, $rootScope, HttpAuth, apiHelper, CONSTANTS,
    menuHeaderService, payerHelper, permissionsHelper) {
    var payerExpertiseService = this;
    var add_to_shortlist_mode = ($location.path().toLowerCase().indexOf('addtoshortlist') > -1);
    const PERMISSIONS = CONSTANTS.getPermissions();

    var surveys_loaded = HttpAuth.get(apiHelper.getApiUrl() + '/api/admin/survey/all.json').then(
      function (response) {
        return response.data;
      },
      function (error) {
        portalHelper.showErrorCommon(error);
      }
    );

    let projectCodesRenderer = (row) => {
      if (row.data[row.column.colId] && Array.isArray(row.data[row.column.colId])) {
        let allSurveys = row.data[row.column.colId];
        let projectCodeHTMLStrings = [];
        for (let i = 0; i < allSurveys.length; i++) {
          projectCodeHTMLStrings.push(
            '<span title="Recruited By: ' + allSurveys[i].recruited_by_full_name +'">' +
            allSurveys[i].project_code + '</span>');
        }
        return projectCodeHTMLStrings.join(', ');
      }
    };

    let projectCodesFilterValueGetter = (row) => {
      return row.data[row.column.colId] && Array.isArray(row.data[row.column.colId])
        ? _.pluck(row.data[row.column.colId], 'project_code')
        : '';
    };

    let truncateStr = function (cellData) {
      let TRUNCATE_LIMIT = 150;
      return portalHelper.truncateString(cellData.value, TRUNCATE_LIMIT);
    };

    let promotionalUseValueGetter = function (params) {
      const key = params.column.colId;
      if (key && params.data[key]) {
        let value = params.data[key];
        return (value === true || value === 'true') ? 'Allowed' : 'Not Allowed';
      }
      return 'Not Allowed';
    };

    let csvSplitterValueGetter = function (params) {
      const key = params.column.colId;
      if (key && params.data[key]) {
        return params.data[key].replace(/;/g, ',').split(',').map((x) => x.trim());
      }
      return '';
    };

    payerExpertiseService.showPopupToGenerateExpertiseFormLink =  function (payerId, countryCode, respondentTypes) {
      var scope = $rootScope.$new(true);

      scope.getFormToken = function (payerId) {
        var url = apiHelper.getApiUrl() + '/api/admin/payer_form/token/';
        var data = { payer: payerId, form: scope.selectedFormId};
        return HttpAuth.post(url, data);
      };

      scope.formSelected = function(selectedFormId) {
        if (scope.selectedFormId !== selectedFormId) {
          scope.payerExpertiseUrl = '';
          scope.selectedFormId = selectedFormId;
        }
      };

      scope.copyToClipBoard = function(data) {
        copyToClipboard(data);
      };

      scope.generate_payer_expertise_link = function(payerId) {
        scope.processing = true;
        scope.getFormToken(payerId)
          .then(function(result) {
            scope.payerExpertiseUrl = $location.protocol() + '://' + $location.host();

            if ($location.port() !== 80) {
              scope.payerExpertiseUrl += ':' + $location.port();
            }

            var IS_PAYER = !!payerId;
            if (IS_PAYER) {
              scope.payerExpertiseUrl += (
                '/#/Payers/PayerExpertiseForm/' + scope.selectedFormId +
                '/true/'+ result.data.token
              );
            }
            else {
              scope.payerExpertiseUrl += (
                '/#/PayerExpertiseForm/' + scope.selectedFormId +
                '/false/' + result.data.token
              );
            }

            scope.processing = false;
          })
          .catch(function(error) {
            portalHelper.showErrorCommon(error);
          })
          .finally(function() {
            scope.processing = false;
          });

      };

      if (payerId) {
        scope.payer_id = payerId;
      }

      var payerExpertiseFormTypes = [];
      menuHeaderService.fetchPayerExpertiseFormTypes().then(
        function (formTypes) {
          payerExpertiseFormTypes = formTypes;

          scope.get_payer_expertise_form_types = function () {

            if (respondentTypes && countryCode) {
              if (respondentTypes.includes('Payer') && respondentTypes.length === 1 && countryCode === 'US') {
                return _.filter(payerExpertiseFormTypes, function(formType) {
                  return formType.name === 'US Payer Expertise Form';
                });
              }
              else if (respondentTypes.includes('Payer') && respondentTypes.length === 1 && countryCode !== 'US') {
                return _.filter(payerExpertiseFormTypes, function(formType) {
                  return formType.name === 'Global Payer Expertise Form';
                });
              }
              else if (respondentTypes.includes('KOL') && respondentTypes.length === 1) {
                return _.filter(payerExpertiseFormTypes, function(formType) {
                  return formType.name === 'Physician/Clinical Expertise Form';
                });
              }
              else if (respondentTypes.includes('KOL') && respondentTypes.includes('Payer') && countryCode === 'US') {
                return _.filter(payerExpertiseFormTypes, function(formType) {
                  return formType.name === 'Physician/Clinical Expertise Form' || formType.name === 'US Payer Expertise Form';
                });
              }
              else if (respondentTypes.includes('KOL') && respondentTypes.includes('Payer') && countryCode !== 'US') {
                return _.filter(payerExpertiseFormTypes, function(formType) {
                  return formType.name === 'Physician/Clinical Expertise Form' || formType.name === 'Global Payer Expertise Form';
                });
              } else {
                return payerExpertiseFormTypes;
              }
            }
            return payerExpertiseFormTypes;
          };

          var title = payerId ? 'Create a link for expertise form for an Existing Payer' :
            'Create a link for expertise form for a Potential Payer/Physician';

          var config = {
            title: title,
            contentUrl: portalHelper.getUrlRev('../html_templates/dialogs/new_payer_expertise_dialog.5645ba84.html'),
            type: 'blue',
            closeIcon: true,
            columnClass: 'medium',
            scope: scope
          };

          if (payerId) {
            config.buttons = {
              OK: {
                text: 'Send Form',
                btnClass: 'mat ui primary button',
                action: function (scope, button) {
                  if (payerId) {
                    payerExpertiseService.sendRequestToExpertiseFormInvite([
                      {
                        form: scope.selectedFormId,
                        payer: payerId
                      }
                    ]);
                  }
                }
              }
            };
          }
          $ngConfirm(config);

        }
      );
    };
    payerExpertiseService. showSendPayerExpertiseFormConfirmation = function (data) {
      var scope = $rootScope.$new(true);
      scope.form_name = data.formName;
      $ngConfirm({
        title: 'Request updated Expertise Form from ' + data.payerName,
        contentUrl: portalHelper.getUrlRev('html_templates/dialogs/admin_send_payer_expertise_form_confirmation.c963edac.html'),
        type: 'blue',
        closeIcon: true,
        escapeKey: 'close',
        columnClass: 'medium',
        scope: scope,
        buttons: {
          OK: {
            text: 'Send Form',
            btnClass: 'mat ui primary button',
            action: function (scope, button) {
              if (data.existingPayer) {
                payerExpertiseService.sendRequestToExpertiseFormInvite([
                  {
                    form: data.formTypeId,
                    payer: data.payerId
                  }
                ]);
              }
              else {
                payerExpertiseService.sendRequestToExpertiseFormInvite([
                  {
                    form: data.formTypeId,
                    email: data.email
                  }
                ]);
              }
            }
          }
        }
      });
    };

    payerExpertiseService.sendExpertiseFormsToPayers = function (payers) {
      let formInvites = [];
      payers.forEach((payerId) => {
        formInvites.push({
          payer: payerId
        });
      });
      return payerExpertiseService.sendRequestToExpertiseFormInvite(formInvites);
    };
    payerExpertiseService.sendRequestToExpertiseFormInvite = function (data) {
      var url = apiHelper.getApiUrl() + '/api/admin/payer_expertise_form/invite.json';
      return HttpAuth.post(url, {invites: data}).then(
        function (response) {
          portalHelper.showToast(
            CONSTANTS.typeOfToasts.SUCCESS,
            'Successfully sent request for updated expertise form data');
          return response;
        },
        function (error) {

          if (error.data && error.data.invites) {
            var erroneousEmailIndexes = [];
            let invites = error.data.invites;
            for (let i = 0; i < invites.length; ++i) {
              if (invites[i].email) {
                erroneousEmailIndexes.push(i);
              }
            }
          }

          if (Array.isArray(erroneousEmailIndexes) && erroneousEmailIndexes.length) {
            var emailsWithError = [];
            for (var i = 0; i < erroneousEmailIndexes.length; i++) {
              var indexOfInvalidEmail = erroneousEmailIndexes[i];
              emailsWithError.push(data[indexOfInvalidEmail].email);
            }
            portalHelper.showToast(CONSTANTS.typeOfToasts.ERROR,
              emailsWithError.join(', '),
              'Invalid Emails');
          }
          else {
            portalHelper.showErrorCommon(error);
          }
          return error;
        }
      );
    };


    payerExpertiseService.showShortlistForSurveyPopup = function (expertiseFormResponses, idKey) {
      if (!expertiseFormResponses || expertiseFormResponses.length === 0) {
        portalHelper.showToast(CONSTANTS.typeOfToasts.ERROR,
          'Please select at least one ' + $rootScope.nomenclature_dict.stakeholder_lower   + '.'
        );
        return;
      }
      var scope = $rootScope.$new(true);
      scope.expertiseFormResponses = expertiseFormResponses;
      scope.idKey = idKey;
      surveys_loaded.then(function(result) {
        scope.surveys = angular.copy(result);
        scope.surveys = _.filter(scope.surveys,
          function(survey) {
            return survey.status !== 'delivered' && survey.status !== 'cancelled' && survey.status !== 'deleted';
          }
        );

        var shortlistPopupHandle = $ngConfirm({
          title: 'Shortlist for survey',
          contentUrl: portalHelper.getUrlRev('html_templates/dialogs/admin_show_shortlist_for_survey.f36ac882.html'),
          type: 'blue',
          closeIcon: true,
          columnClass: 'small',
          scope: scope,
          buttons: {
            OK: {
              text: 'Shortlist ' +  $rootScope.nomenclature_dict.stakeholders_title,
              btnClass: 'mat ui primary button',
              action: function (scope, button) {

                if (!scope.selected_survey) {
                  portalHelper.showToast(CONSTANTS.typeOfToasts.ERROR,
                    'Please select a survey for which ' + $rootScope.nomenclature_dict.stakeholders_lower  +  ' need to be assigned',
                    'No survey is selected');
                  return false;
                }

                button.setDisabled(true);
                var url = apiHelper.getApiUrl() + '/api/admin/survey/shortlist_payer/add.json';
                var idKey = scope.idKey == null ? 'id' : scope.idKey;

                var data = {
                  survey: scope.selected_survey.id,
                  payer_form_responses: _.pluck(expertiseFormResponses, idKey)
                };
                HttpAuth.post(url, data).then(
                  function (result) {
                    shortlistPopupHandle.close();
                    let popupContent = `<p> ${result.data.success} ${$rootScope.nomenclature_dict.stakeholders_lower} shortlisted successfully, ${result.data.omit} omitted and ${result.data.already_existed_errors.length} errors </p>`;

                    if (result.data.already_existed_errors.length) {
                      popupContent += '<p>Errors are: <br>';
                      result.data.already_existed_errors.forEach((error, index) => {
                        popupContent += (index + 1) + ') ' + error + '<br>';
                      });
                      popupContent += '</p>';
                    }

                    portalHelper.showPopup('Alert', popupContent, null, 'blue');
                  },
                  function (error) {
                    shortlistPopupHandle.close();
                    portalHelper.showErrorCommon(error);
                  }
                ).finally(
                  function () {
                    shortlistPopupHandle.close();
                    button.setDisabled(false);
                  }
                );

                return false;
              }
            }
          }
        });

        scope.get_filtered_surveys = function (searchText) {
          if (searchText) {
            return _.filter(scope.surveys, function (survey) {
              return survey.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
                survey.prefixed_id.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
                survey.project_code_db.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
            });
          }
          else {
            return [];
          }
        };

        scope.select_survey = function (survey) {
          scope.selected_survey = survey;
        };

        scope.reset_selected_survey = function (survey) {
          scope.selected_survey = null;
        };

      });
    };

    payerExpertiseService.showSendBulkFormsPopup = function (emails) {
      var scope = $rootScope.$new(true);

      menuHeaderService.fetchPayerExpertiseFormTypes().then(
        function (formTypes) {
          scope.payer_expertise_form_types = formTypes;
        }
      );

      scope.str_emails_textarea = '';
      if (emails && emails.length > 0) {
        scope.str_emails_textarea = angular.copy(emails).join(', ');
      }

      scope.selectedBulkFormTypeId = null;
      scope.bulkFormTypeSelected = function (formTypeId) {
        scope.selectedBulkFormTypeId = formTypeId;
      };

      var bulkExpertiseFormsPopup = $ngConfirm({
        title: 'Send bulk forms to Potential Payers/Physicians',
        contentUrl: portalHelper.getUrlRev('html_templates/dialogs/admin_send_bulk_forms.b041b4f0.html'),
        type: 'blue',
        closeIcon: true,
        columnClass: 'medium',
        scope: scope,
        buttons: {
          OK: {
            text: 'Send Form',
            btnClass: 'mat ui primary button',
            action: function (scope, button) {

              if (!scope.selectedBulkFormTypeId) {
                portalHelper.showToast(
                  CONSTANTS.typeOfToasts.ERROR,
                  'Please select a expertise form type to be sent to the ' +  $rootScope.nomenclature_dict.stakeholders_lower,
                  'Form Type not selected');
                return false;
              }

              var emails = scope.str_emails_textarea.split(/[\n,]/);
              for (var i = 0; i < emails.length; i++) {
                emails[i] = emails[i].trim();
                if (emails[i] === '') {
                  emails.splice(i, 1);
                  i--;
                }
              }

              emails = [...(new window.Set(emails))];

              if (!emails || emails.length === 0) {
                portalHelper.showToast(CONSTANTS.typeOfToasts.ERROR,
                  'Please enter atleast one valid email',
                  'No email entered');
                return false;
              }

              let formInvites = [];
              emails.forEach((email) => {
                formInvites.push({
                  form: scope.selectedBulkFormTypeId,
                  email: email
                });
              });
              var payersInvited = payerExpertiseService.sendRequestToExpertiseFormInvite(formInvites);

              payersInvited.then(
                function (result) {
                  var SUCCESS = 204;
                  if (result.status === SUCCESS) {
                    bulkExpertiseFormsPopup.close();
                  }
                }
              );

              return false;
            }
          }
        }
      });

      portalHelper.set_auto_size_text_areas('expandable', 1000);

    };

    payerExpertiseService.delete_expertise_form = function (formId) {
      return HttpAuth.delete(apiHelper.getApiUrl() + '/api/admin/payer_form_response/' + formId + '.json').then(
        function (response) {
          portalHelper.showToast(CONSTANTS.typeOfToasts.SUCCESS, 'Expertise from deleted successfully.');
        }, function(error) {
          portalHelper.showErrorCommon(error);
          throw error;
        }
      );
    };

    var dateTimeComparator = function (date1, date2) {
      return new Date(date2) - new Date(date1);
    };

    var dateComparator = function (date1StringISO, date2StringISO) {
      let date1 = new Date(date1StringISO);
      let date2 = new Date(date2StringISO);
      let date2WithoutTime = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
      let date1WithoutTime = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
      return date2WithoutTime - date1WithoutTime;
    };

    var dateValueGetter = function (row) {
      let dateString = row.data[row.column.colId];
      if (dateString) {
        return moment(dateString).local().toDate().toISOString();
      }
    };

    var dateFormatter = function (row) {
      if (row.data && row.column.colId in row.data) {
        let dateString = row.data[row.column.colId];
        if (dateString) {
          return portalHelper.convert_to_local_time(dateString);
        }
      }
    };

    var dateFormatterOnlyDate = function (row) {
      if (row.data && row.column.colId in row.data) {
        let dateString = row.data[row.column.colId];
        if (dateString) {
          return portalHelper.convert_to_local_time(dateString, CONSTANTS.dateTimeFormatMoment.MEDIUM_ONLY_DATE);
        }
      }
    };

    var getActionItems = function (params) {
      let isLockedPayer = !!params.data.meta__payer.locked;
      let isActivePayer = !!params.data.meta__payer.active;
      let isPotentialPayer = !params.data.meta__payer.id;
      let isLastLoginAvailable = !!params.data.meta__info.last_login;

      let addToNetworkNode =  `<li>
        <a target="_blank" class="clickable" click-handler="add_to_rpr_network">
          <i class="fa fa-user-plus"></i>
          Add to RPR Network
        </a>
      </li>`;

      let shortlistForSurveyNode = `<li>
        <a target="_blank" class="clickable" click-handler="shortlist_single_payer_for_survey"` +
          ( isPotentialPayer || (isActivePayer && !isLockedPayer) ? '' : ' disabled="disabled" title="User is Locked or Inactive"') +
        `>
          <i class="fa fa-check-square-o"></i>
          Shortlist For Survey
        </a>
      </li>`;

      let viewExpertiseDataNode = `<li>
        <a click-handler="view_expertise_data"
          target="_blank" href="#/Admin/Payer/Expertise/${params.data.form_response_id}">
          <i class="fa fa-list-alt"></i>
          View Expertise Data
        </a>
      </li>`;


      function getPermittedActionNodes(actionNodes) {
        let nodes = [];
        for (let i = 0; i < actionNodes.length; ++i) {
          let nodePermission = actionNodes[i].permission;
          if (!nodePermission || permissionsHelper.hasPermission(nodePermission)) {
            nodes.push(actionNodes[i].node);
          }
        }
        return nodes;
      }


      if (params.data.meta__payer.id) {

        let accessOnBehalfNode = `<li>
          <a target="_blank" click-handler="impersonate_by_admin" class="clickable">
          <i class="fa fa-user-secret"></i>
          Access on behalf of</a>
        </li>`;

        let activatePayerNode = `<li>
          <a class="clickable" click-handler="activate_payer">
            <i class="fa fa-check"></i>
            Activate ${$rootScope.nomenclature_dict.stakeholder_title}
          </a>
        </li>`;

        let activityReportNode = `<li>
          <a click-handler="show_activity_report" class="clickable">
          <i class="fa fa-table"></i>
          Activity Report</a>
        </li>`;

        let additionalFeesNode = `<li>
          <a target="_blank" href="#/Admin/Payer/AdditionalFees/${params.data.meta__payer.id}">
            <i class="fa fa-usd"></i>
            Additional Fees
          </a>
        </li>`;

        let deactivatePayerNode = `<li>
          <a class="clickable" click-handler="deactivate_payer">
            <i class="fa fa-ban"></i>
            De-activate ${$rootScope.nomenclature_dict.stakeholder_title}
          </a>
        </li>`;

        let editPayerNode = `<li>
          <a target="_blank" class="clickable" href="#/Admin/Payer/Edit/${params.data.meta__payer.id}">
            <i class="fa fa-pencil"></i>
            Edit ${$rootScope.nomenclature_dict.stakeholder_title}
          </a>
        </li>`;

        let emailPasswordResetNode = `<li>
          <a class="clickable" click-handler="email_password_reset"` +
          ((isActivePayer && !isLockedPayer) ? '' : ' disabled="disabled" title="User is Locked or Inactive"') +
        `>
            <i class="fa fa-key"></i>
            Email Password Reset
          </a>
        </li>`;

        let hrNode = '<li role="separator" class="divider"></li>';

        let lockPayerNode = `<li>
          <a click-handler="lock_payer" class="clickable">
          <i class="fa fa-lock"></i>
          Lock ${$rootScope.nomenclature_dict.stakeholder_title}</a>
        </li>`;

        let passwordResetNode = `<li>
          <a class="clickable" click-handler="password_reset_link_as_admin"` +
            ((isActivePayer && !isLockedPayer) ? '' : ' disabled="disabled" title="User is Locked or Inactive"') +
          `>
            <i class="fa fa-link"></i>
            Password Set/Reset Link
          </a>
        </li>`;

        let requestUpdatedExpertiseDataNode = `<li>
            <a class="clickable" click-handler="request_updated_expertise_data"` +
              ((isActivePayer && !isLockedPayer) ? '' : ' disabled="disabled" title="User is Locked or Inactive"') +
            `>
              <i class="fa fa-refresh" ></i>
              Request Updated Expertise
            </a>
        </li>`;

        let sendWelcomeEmailNode = `<li>
          <a class="clickable" click-handler="send_welcome_email"` +
            ((isActivePayer && !isLockedPayer) ? '' : ' disabled="disabled" title="User is Locked or Inactive"') +
          `>
            <i class="fa fa-paper-plane"></i>
            Send Welcome Email
          </a>
        </li>`;

        let statisticsNode = `<li>
          <a target="_blank" href="#/Admin/PayerStatistics/` + params.data.meta__payer.id + `" class="clickable" target="_blank">
          <i class="fa fa-pie-chart"></i>
          Statistics</a>
        </li>`;

        let unlockPayerNode = `<li>
          <a click-handler="unlock_payer" class="clickable">
            <i class="fa fa-unlock"></i>
          Unlock</a>
        </li>`;

        let viewPayerNode = `<li>
          <a target="_blank" href="#/Admin/Payer/View/${params.data.meta__payer.id}">
            <i class="fa fa-user"></i>
            View ${$rootScope.nomenclature_dict.stakeholder_title}
          </a>
        </li>`;

        let existingPayerSpecificActionItems = [
          {
            node:requestUpdatedExpertiseDataNode,
            permission:PERMISSIONS.CAN_UPDATE_PAYER
          },
          {
            node: shortlistForSurveyNode,
            permission: null
          },
          {
            node: viewExpertiseDataNode,
            permission: null
          },
          {
            node: editPayerNode,
            permission: PERMISSIONS.CAN_UPDATE_PAYER
          },
          {
            node: viewPayerNode,
            permission: PERMISSIONS.CAN_VIEW_PAYER
          },
          {
            node: accessOnBehalfNode,
            permission: PERMISSIONS.CAN_ACCESS_ON_BEHALF_OF_PAYER
          },
          {
            node: activityReportNode,
            permission: PERMISSIONS.CAN_EXPORT_PAYER_REPORT
          },
          {
            node: statisticsNode,
            permission: PERMISSIONS.CAN_VIEW_PAYER
          },
          {
            node: hrNode,
            permission: PERMISSIONS.CAN_UPDATE_PAYER
          },
          {
            node: additionalFeesNode,
            permission: PERMISSIONS.CAN_UPDATE_PAYER
          },
          {
            node: (isLockedPayer ? unlockPayerNode : lockPayerNode),
            permission: PERMISSIONS.CAN_UPDATE_PAYER
          },
          {
            node: (isActivePayer ? deactivatePayerNode : activatePayerNode),
            permission: PERMISSIONS.CAN_UPDATE_PAYER
          },
          {
            node: (isLastLoginAvailable ? emailPasswordResetNode : sendWelcomeEmailNode),
            permission: PERMISSIONS.CAN_UPDATE_PAYER
          },
          {
            node: passwordResetNode,
            permission: PERMISSIONS.CAN_UPDATE_PAYER
          }
        ];

        return getPermittedActionNodes(existingPayerSpecificActionItems);
      }
      else {
        let potentialPayerSpecificActionItems = [
          {
            node: addToNetworkNode,
            permission: PERMISSIONS.CAN_ADD_PAYER
          },
          {
            node: shortlistForSurveyNode,
            permission: null
          },
          {
            node: viewExpertiseDataNode,
            permission: null
          }
        ];

        return getPermittedActionNodes(potentialPayerSpecificActionItems);
      }

    };


    payerExpertiseService.getClickHandlers = function (params) {
      return {
        'add_to_rpr_network': function (rowData) {
          var url = '/admin/#/Admin/Payer/FromExpertiseForm/' + rowData.form_response_id;
          portalHelper.popup_window_open(url);
        },


        'impersonate_by_admin': function (rowData) {
          portalHelper.impersonateByAdmin(rowData.meta__payer.id);
        },


        'lock_payer': function (rowData) {
          let arePayers = true;
          let lockPayer = true;
          portalHelper.update_lock_status_for_users(
            [rowData.meta__payer], lockPayer, arePayers
          ).then((result) => {
            rowData.meta__payer.locked = true;
            payerExpertiseService.reRenderAPSGridWithUpdatedData(
              [rowData.meta__payer]
            );
          });
        },


        'unlock_payer': function (rowData) {
          let arePayers = true;
          let lockPayer = false;
          portalHelper.update_lock_status_for_users(
            [rowData.meta__payer], lockPayer, arePayers
          ).then((result) => {
            rowData.meta__payer.locked = false;
            payerExpertiseService.reRenderAPSGridWithUpdatedData(
              [rowData.meta__payer]
            );
          });
        },


        'activate_payer': function (rowData) {
          let arePayers = true;
          let activatePayer = true;
          portalHelper.update_active_status_for_users(
            [rowData.meta__payer], activatePayer, arePayers
          ).then((result) => {
            rowData.meta__payer.active = true;
            payerExpertiseService.reRenderAPSGridWithUpdatedData(
              [rowData.meta__payer]
            );
          });
        },


        'deactivate_payer': function (rowData) {
          let arePayers = true;
          let activatePayer = true;
          portalHelper.update_active_status_for_users(
            [rowData.meta__payer], !activatePayer, arePayers
          ).then((result) => {
            rowData.meta__payer.active = false;
            payerExpertiseService.reRenderAPSGridWithUpdatedData(
              [rowData.meta__payer]
            );
          });
        },


        'request_updated_expertise_data': function (rowData) {
          if (rowData.meta__payer) {
            payerExpertiseService.showSendPayerExpertiseFormConfirmation({
              existingPayer: true,
              payerName: rowData.meta__payer.full_name,
              formName: rowData.meta__form_name,
              payerId: rowData.meta__payer.id,
              formTypeId: rowData.meta__form_type_id
            });
          }
          else {
            payerExpertiseService.showSendPayerExpertiseFormConfirmation(
              {
                existingPayer: false,
                payerName: rowData.meta__payer.full_name,
                formName: rowData.meta__form_name,
                email: rowData.email,
                formTypeId: rowData.meta__form_type_id
              }
            );
          }
        },

        'send_welcome_email': function (rowData) {
          let payerId = rowData.meta__payer.id;
          portalHelper.sendWelcomeEmail(payerId, 'payer');
        },

        'email_password_reset': function (rowData) {
          let payerId = rowData.meta__payer.id;
          portalHelper.passwordResetAsAdmin(payerId, 'payer');
        },

        'password_reset_link_as_admin': function (rowData) {
          let payerId = rowData.meta__payer.id;
          portalHelper.passwordResetLinkAsAdmin(payerId, 'payer');
        },

        'shortlist_single_payer_for_survey': function (rowData) {
          payerExpertiseService.showShortlistForSurveyPopup([rowData], 'form_response_id');
        },

        'show_activity_report': function (rowData) {
          payerHelper.handle_payer_activity_report(rowData.meta__payer.id);
        },

        'view_expertise_data': function (rowData) {
          payerExpertiseService.saveAPSPageState();
        },

        'view_existing_payer': function(rowData) {
          if (rowData.meta__payer && rowData.meta__payer.id) {
            var url = '/admin/#/Admin/Payer/View/' + rowData.meta__payer.id;
            portalHelper.popup_window_open(url);
          }
        }

      };
    };

    var actionsCellRenderer = function () { };
    actionsCellRenderer.prototype = {
      init: function (params) {
        this.actionsButton = document.createElement('div');

        let buttonHTML = `<i class="fa fa-bars fa-fw dropdown-toggle clickable blue"
            aria-expanded="false" aria-haspopup="true"
            data-bs-toggle="dropdown">
          </i>
          <ul class="mat ui dropdown-menu">` +
            getActionItems(params).join('') +
          '</ul>';

        this.actionsButton.innerHTML = buttonHTML;
      },
      getGui: function () {
        return this.actionsButton;
      },
      refresh: function () {
        return true;
      },
      destroy: function () {
        $(this.actionsButton).remove();
      },
      isPopup: function () {
        return true;
      }
    };

    let getDefaultQuickFilterText = (params) => {
      return params.value;
    };

    let lockedUnlockedValueGetter = (params) => {
      let value = params.data[params.column.colId];
      if (value != null) {
        return (value === true || value === 'true') ? 'Locked' : 'Unlocked';
      }
      return '';
    };

    let activeDeactivatedValueGetter = (params) => {
      let value = params.data[params.column.colId];
      if (value != null) {
        return (value === true || value === 'true') ? 'Active' : 'Deactivated';
      }
      return '';
    };

    let yesNoValueFormatter = (params) => {
      if (params.value != null) {
        return (params.value === true || params.value === 'true') ? 'Yes' : 'No';
      }
      return 'No';
    };

    var emailCellRenderer = function () {};
    emailCellRenderer.prototype = {
      init: function (params) {
        if (params.data.email) {
          this.emailLink = document.createElement('div');
          let emailLinkHtml = '<a href="mailto:'+
          params.data.email +
          '" target="_blank">'+
          params.data.email +
          '</a>';
          this.emailLink.innerHTML = emailLinkHtml;
        }
      },
      getGui: function () {
        return this.emailLink;
      },
      refresh: function () {
        return true;
      },
      destroy: function () {
        $(this.emailLink).remove();
      },
      isPopup: function () {
        return false;
      }
    };

    var PAYER_EXPERTISE_COLUMNS = [
      {
        field: 'meta__active',
        headerName: 'Active/Deactivated',
        valueGetter: activeDeactivatedValueGetter,
        filter: 'agSetColumnFilter'
      },{
        field: 'meta__new_payer',
        headerName: 'New Payer',
        valueGetter: function (params) {
          let value = params.data[params.column.colId];
          return (value ? 'Yes' : 'No');
        },
        filter: 'agSetColumnFilter'
      },
      {
        field: 'Id',
        enableRowGroup: false,
        hide: true,
        filter: false
      },
      {
        field: 'assignment',
        hide: true,
        filter: false
      },
      {
        field: 'anonymized_profile',
        headerName: 'Anonymized Profile'
      },
      {
        field: 'archetypes_used',
        headerName: 'ArcheTypes'
      },
      {
        field: 'linkedin_url',
        headerName: 'LinkedIn URL'
      },
      {
        field: 'shortlisted_id',
        hide: true,
        filter: false
      },
      {
        field: 'actions',
        headerName: ' ',
        width: 50,
        enableRowGroup: false,
        cellRenderer: actionsCellRenderer,
        pinned: 'left',
        cellClass: 'actions-menu-cell',
        suppressMenu: true,
        filter: false
      },
      { field: 'assignments_sent_count', headerName: 'Total Surveys Sent', columnGroupShow: 'always', filter: 'agNumberColumnFilter', cellClass: 'numberType'},
      {
        field: 'meta__is_payer_existing_or_potential',
        headerName: 'Potential / Existing',
        columnGroupShow: 'always'
      },
      {
        field: 'form_requested_by_full_name',
        headerName: 'Form Requested By'
      },
      {
        field: 'form_updated_by_full_name',
        headerName: 'Form Updated By'
      },
      {
        field: 'form_submitted_by_full_name',
        headerName: 'Form Submitted By',
        valueGetter: function (row) {
          if (!row.data[row.column.colId] && row.data['form_submitted_datetime']) {
            if (row.data.meta__payer.id) {
              return 'RPR';
            }
            else {
              return row.data.meta__payer.full_name;
            }
          }
          return row.data[row.column.colId];
        }
      },
      { field: 'relevance_rating_last_survey', headerName: 'Relevance - Last Survey', columnGroupShow: 'always', filter: 'agNumberColumnFilter', cellClass: 'numberType' },
      {
        field: 'meta__salutation',
        headerName: 'Title',
        hide: true,
        filter: 'agTextColumnFilter'
      },
      {
        field: 'firstname',
        headerName: 'First Name',
        pinned: 'left',
        getQuickFilterText: getDefaultQuickFilterText,
        headerCheckboxSelectionFilteredOnly: true,
        headerCheckboxSelection: !add_to_shortlist_mode,
        filter: 'agTextColumnFilter',
        checkboxSelection: function (params) {
          if (add_to_shortlist_mode) {
            if (params.data.assignment) {
              return false;
            }
          }
          return true;
        }
      },
      {
        field: 'lastname',
        headerName: 'Last Name',
        getQuickFilterText: getDefaultQuickFilterText,
        pinned: 'left',
        filter: 'agTextColumnFilter'
      },
      {
        field: 'email',
        headerName: 'Email ID',
        getQuickFilterText: getDefaultQuickFilterText,
        cellRenderer: emailCellRenderer,
        filter: 'agTextColumnFilter'
      },
      { field: 'mobilephone', headerName: 'Mobile Number', enableRowGroup: false, filter: 'agTextColumnFilter' },
      { field: 'country_of_residence', headerName: 'Country of Residence' },
      { field: 'country_of_service', headerName: 'Country of Expertise', columnGroupShow: 'always'},
      { field: 'professional_qualifications', headerName: 'Professional Qualifications' },
      {
        field: 'payer_position_category',
        headerName: 'Payer Categorization',
        columnGroupShow: 'always',
        valueGetter: function (params) {
          return params.data.payer_position_category ? params.data.payer_position_category.value : '';
        },
        tooltipValueGetter: function (params) {
          return portalHelper.removeHTMLTags(params.data.payer_position_category ? params.data.payer_position_category.value_display_text: '');
        }
      },
      {
        field: 'experiential_expertise',
        headerName: 'Experiential Expertise',
        filter: 'agSetColumnFilter',
        filterParams: {
          valueGetter: csvSplitterValueGetter
        }
      },
      {
        field: 'additional_information',
        headerName: 'Additional Information',
        hide: true,
        getQuickFilterText: getDefaultQuickFilterText,
        filter: 'agTextColumnFilter'
      },
      {
        field: 'profile',
        headerName: 'Profile',
        hide: true,
        getQuickFilterText: getDefaultQuickFilterText,
        filter: 'agTextColumnFilter'
      },
      { field: 'clinical_functional_expertise', headerName: 'Clinical Expertise' },
      { field: 'coverage_functional_expertise', headerName: 'Coverage Expertise' },
      { field: 'economic_evaluation_functional_expertise', headerName: 'Economic Evaluation Expertise' },
      {
        field: 'expertise_form_status',
        headerName: 'Expertise Form Status',
        columnGroupShow: 'always'
      },
      { field: 'pricing_functional_expertise', headerName: 'Pricing Expertise' },
      { field: 'tendering_contracting_functional_expertise', headerName: 'Tendering Contracting Expertise' },
      { field: 'hta_functional_expertise', headerName: 'HTA Expertise' },
      { field: 'private_payer_functional_expertise', headerName: 'Private Payer Expertise' },
      { field: 'public_payer_functional_expertise', headerName: 'Public Payer Expertise' },
      { field: 'reimbursement_functional_expertise', headerName: 'Reimbursement Expertise' },
      {
        field: 'disease_category',
        headerName: 'Disease Category/Area',
        columnGroupShow: 'always',
        filter: 'agSetColumnFilter',
        filterParams: {
          valueGetter: csvSplitterValueGetter
        }
      },
      {
        field: 'kol_disease_category',
        headerName: 'Medical Specialities',
        columnGroupShow: 'always',
        filter: 'agSetColumnFilter',
        filterParams: {
          valueGetter: csvSplitterValueGetter
        }
      },
      {
        field: 'primary_expertise',
        headerName: 'Primary Expertise Area',
        getQuickFilterText: getDefaultQuickFilterText,
        columnGroupShow: 'always'
      },
      {
        field: 'secondary_expertise',
        headerName: 'Secondary Expertise Area',
        getQuickFilterText: getDefaultQuickFilterText,
        columnGroupShow: 'always'
      },
      { field: 'primary_expertise_elaboration', headerName: 'Primary Expertise Elaboration' },
      { field: 'secondary_expertise_elaboration', headerName: 'Secondary Expertise Elaboration' },
      { field: 'timezone', headerName: 'Timezone' },
      { field: 'remuneration_currency', headerName: 'Remuneration Currency' },
      { field: 'remuneration_rate', headerName: 'Remuneration Rate', filter: 'agNumberColumnFilter', cellClass: 'numberType' },
      { field: 'payment_currency', headerName: 'Payment Currency' },
      { field: 'meta__payer_type', headerName: 'RPR-Payer Type' },
      {
        field: 'meta__payer_identifiers',
        headerName: 'Key Identifiers/Buzz Words',
        getQuickFilterText: getDefaultQuickFilterText,
        valueGetter: function (params) {
          if (params.data.meta__payer_identifiers) {
            return params.data.meta__payer_identifiers.split(',');
          }
          else {
            return '';
          }
        }
      },
      { field: 'payer_type', headerName: 'US - Payer Type', columnGroupShow: 'always' },
      {
        field: 'company',
        headerName: 'Organization',
        getQuickFilterText: getDefaultQuickFilterText,
        columnGroupShow: 'always'
      },
      {
        field: 'jobtitle',
        headerName: 'Job Title',
        getQuickFilterText: getDefaultQuickFilterText,
        columnGroupShow: 'always'
      },
      { field: 'organization_state', headerName: 'Current - Organization State' },
      {
        field: 'years_of_experience',
        headerName: 'Years of Experience'
      },
      { field: 'government_employee', headerName: 'Current - Government Employee' },
      { field: 'prescribe_license', headerName: 'Current - Licensed to prescribe drugs by either Minnesota or Vermont' },
      { field: 'vermont_practice_status', headerName: 'Current - Practice or work in Vermont' },
      { field: 'us_organization_type', headerName: 'Current/Former - Country Specific Type', columnGroupShow: 'always' },
      { field: 'former_organization', headerName: 'Former - Organization', columnGroupShow: 'always' },
      { field: 'former_jobtitle', headerName: 'Former - Job Title/Role', columnGroupShow: 'always' },
      { field: 'former_organization_leave_year', headerName: 'Former - Year of leaving Organization' },
      { field: 'former_organization_no_of_years', headerName: 'Former - Years of Experience', cellClass: 'numberType', filter: 'agNumberColumnFilter' },
      { field: 'former_organization_state', headerName: 'Former - Organization State' },
      { field: 'idn_payers_business_side', headerName: 'IDN - Plan Name' },
      { field: 'mco_current_voting_member', headerName: 'MCO - Current Voting Member of P&T Committee' },
      {
        field: 'meta__locked',
        headerName: 'Unlocked/Locked',
        valueGetter: lockedUnlockedValueGetter,
        filter: 'agSetColumnFilter'
      },
      {
        field: 'meta__notes',
        headerName: 'Pinned Notes',
        getQuickFilterText: getDefaultQuickFilterText,
        autoHeight: true,
        cellStyle: { 'white-space': 'normal' },
        cellRenderer: params => params.value,
        tooltipValueGetter: (params) => portalHelper.removeHTMLTags(params.value),
        filter: false
      },
      { field: 'meta__timezone', headerName: 'Timezone' },
      { field: 'alternate_email_id', headerName: 'Alternate Email ID' },
      { field: 'meta__do_not_contact', headerName: 'Do Not Contact' },
      { field: 'mco_plan', headerName: 'MCO - Description of Plan' },
      { field: 'mco_responsibility', headerName: 'MCO - Plan Responsibility' },
      { field: 'mco_experience', headerName: 'MCO - Additional Experience' },
      { field: 'mco_plan_description', headerName: 'MCO - Level of Control/Management' },
      { field: 'managed_care_roles', headerName: 'MCO - Type of work' },
      { field: 'managed_care_roles_other', headerName: 'MCO - Type of work (Elaborate Other)' },
      { field: 'role_description', headerName: 'ACO/MCO/PBM - Decision Making/Policy Setting Role' },
      { field: 'plan_total_no_lives', headerName: 'IDN/MCO/PBM - Total Lives', cellClass: 'numberType', filter: 'agNumberColumnFilter' },
      { field: 'plan_commercial_covered_lives', headerName: 'IDN/MCO/PBM - Commercial %', cellClass: 'numberType', filter: 'agNumberColumnFilter' },
      { field: 'plan_medicare_covered_lives', headerName: 'IDN/MCO/PBM - Medicare %', cellClass: 'numberType', filter: 'agNumberColumnFilter' },
      { field: 'medicare_with_part_d_advantage', headerName: 'IDN/MCO/PBM - Medicare % Advantage + Part-D', cellClass: 'numberType', filter: 'agNumberColumnFilter' },
      { field: 'medicare_without_part_d_advantage', headerName: 'IDN/MCO/PBM - Medicare % Advantage without Part-D', cellClass: 'numberType', filter: 'agNumberColumnFilter' },
      { field: 'medicare_standalone_part_d', headerName: 'IDN/MCO/PBM - Medicare % Stand-alone Part-D', cellClass: 'numberType', filter: 'agNumberColumnFilter' },
      { field: 'plan_managed_medicaid_covered_lives', headerName: 'IDN/MCO/PBM - Managed Medicaid %', cellClass: 'numberType', filter: 'agNumberColumnFilter' },
      { field: 'plan_exchange_covered_lives', headerName: 'IDN/MCO/PBM - Exchange %', cellClass: 'numberType', filter: 'agNumberColumnFilter' },
      { field: 'plan_veterans_covered_lives', headerName: 'IDN/MCO/PBM - VA/Veterans %', cellClass: 'numberType', filter: 'agNumberColumnFilter' },
      { field: 'meta__respondent_type', headerName: 'Respondent Type', columnGroupShow: 'always'},
      { field: 'meta__payer_type', headerName: 'Respondent Type', columnGroupShow: 'always'},
      { field: 'us_organization_role_years', headerName: 'Years in this Role', cellClass: 'numberType', filter: 'agNumberColumnFilter' },
      { field: 'us_organization_buy_power', headerName: 'GPO - Buying Power of Organization', filter: 'agNumberColumnFilter' },
      { field: 'hospital_organization_description', headerName: 'Hospital/Practice - Organization' },
      { field: 'hospital_organization_description_other', headerName: 'Hospital/Practice - Organization (Elaborate Other)' },
      { field: 'is_eligible_340b_drug_pricing_program', headerName: 'Hospital/Practice - 340B Drug Pricing Program' },
      { field: 'hospital_organization_specialties', headerName: 'Hospital/Practice - Organization Specialities' },
      { field: 'hospital_patient_care_type', headerName: 'Hospital/Practice - Patient Care' },
      {
        field: 'hospital_care_count',
        headerName: 'Hospital/Practice - No. of Patients care each year',
        cellClass: 'numberType',
        filter: 'agNumberColumnFilter'
      },
      { field: 'state_federal_organization_role', headerName: 'State/Federal - Job Title/Role' },
      { field: 'state_federal_organization_role_other', headerName: 'State/Federal - Job Title/Role (Elaborate Other)' },
      { field: 'state_federal_organization_description', headerName: 'State/Federal - Organization' },
      { field: 'state_federal_organization_description_other', headerName: 'State/Federal - Organization Description Other' },
      { field: 'organization_lives', headerName: 'State/Federal - Lives represented', filter: 'agNumberColumnFilter', cellClass: 'numberType' },
      { field: 'organization_jurisdiction_level', headerName: 'State/Federal - Level of Jurisdiction' },
      { field: 'organization_jurisdiction_level_other', headerName: 'State/Federal - Level of Jurisdiction (Elaborate Other)' },
      { field: 'us_organization_description', headerName: 'Other Org. Type - Organization' },
      { field: 'us_organization_role', headerName: 'Your role in the Organization' },
      { field: 'us_organization_patient_care_count', headerName: 'Other Org. Type - Lives Represented/Provided Care per year', cellClass: 'numberType', filter: 'agNumberColumnFilter' },
      { field: 'state', headerName: 'State/Region' },
      {
        field: 'is_healthcare_provider',
        headerName: 'HCP',
        valueFormatter: yesNoValueFormatter,
        filter: 'agSetColumnFilter',
        filterParams: {
          valueFormatter: yesNoValueFormatter
        }
      },
      {
        field: 'localities',
        headerName: 'Locality',
        filter: 'agSetColumnFilter',
        filterParams: {
          valueGetter: csvSplitterValueGetter
        }
      },
      { field: 'meta__payer_director_type', headerName: 'PD/MD (USA ONLY)' },
      { field: 'relevance_rating_last_three_survey', headerName: 'Relevance - Average of last 3 surveys', filter: 'agNumberColumnFilter', cellClass: 'numberType' },
      { field: 'clarifications_requested_count', headerName: 'Total Number of clarifications requested', filter: 'agNumberColumnFilter', cellClass: 'numberType' },
      { field: 'clarifications_requested_count_for_last_5_surveys', headerName: 'Total Number of clarifications requested for last 5 surveys', filter: 'agNumberColumnFilter', cellClass: 'numberType' },
      { field: 'surveys_in_last_month', headerName: 'Total no. of Surveys in last month', filter: 'agNumberColumnFilter', cellClass: 'numberType' },
      { field: 'surveys_in_last_3_months', headerName: 'Total no. of Surveys in last 3 months', filter: 'agNumberColumnFilter', cellClass: 'numberType' },
      { field: 'surveys_in_last_6_months', headerName: 'Total no. of Surveys in last 6 months', filter: 'agNumberColumnFilter', cellClass: 'numberType' },
      { field: 'surveys_in_last_year', headerName: 'Total no. of Surveys in last year', filter: 'agNumberColumnFilter', cellClass: 'numberType' },
      { field: 'avg_days_response', headerName: 'Average Number Of Days To Respond', filter: 'agNumberColumnFilter', cellClass: 'numberType' },
      { field: 'surveys_per_month', headerName: 'Surveys Per Month', filter: 'agNumberColumnFilter', cellClass: 'numberType' },
      {
        field: 'acceptance_percentage',
        headerName: 'Percentage Accepted',
        valueFormatter: (params) => {
          return params.value ? `${params.value}%` : '';
        },
        filter: 'agNumberColumnFilter',
        cellClass: 'numberType'
      },
      { field: 'completion_percentage', headerName: '% Responses', filter: 'agNumberColumnFilter', cellClass: 'numberType' },
      { field: 'completion_on_time_percentage', headerName: '% Responded In Time', filter: 'agNumberColumnFilter', cellClass: 'numberType' },
      { field: 'assignments_accepted_count', headerName: 'Total Surveys Accepted', filter: 'agTextColumnFilter', cellClass: 'numberType' },
      { field: 'completed_survey_count', headerName: 'Total Surveys Submitted', filter: 'agNumberColumnFilter', cellClass: 'numberType' },
      { field: 'days_since_last_participation', headerName: 'Days Since Last Response', filter: 'agNumberColumnFilter' , cellClass: 'numberType'},
      { field: 'patients_treated_per_month', headerName: 'Patients treated per month', filter: 'agNumberColumnFilter', cellClass: 'numberType' },
      {
        field: 'disease_areas',
        headerName: 'Areas of Research Interest',
        filter: 'agSetColumnFilter',
        filterParams: {
          valueGetter: csvSplitterValueGetter
        }
      },
      {
        field: 'patient_type',
        headerName: 'Patient Type',
        filter: 'agSetColumnFilter',
        filterParams: {
          valueGetter: csvSplitterValueGetter
        }
      },
      {
        field: 'form_generated_datetime',
        headerName: 'Form Generated On',
        cellClass: 'dateType',
        valueFormatter: dateFormatter,
        comparator: dateTimeComparator,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateComparator,
          valueFormatter: dateFormatter
        }
      },
      {
        field: 'key_responsibilities',
        headerName: 'Key Responsibilities',
        filterParams: {
          valueGetter: csvSplitterValueGetter
        }
      },
      {
        field: 'surveys_invited',
        headerName: 'Surveys Invited To',
        valueGetter: (row) => portalHelper.removeHTMLTags(projectCodesRenderer(row)),
        cellRenderer: projectCodesRenderer,
        filter: 'agSetColumnFilter',
        filterParams: {
          valueGetter: projectCodesFilterValueGetter
        }
      },
      {
        field: 'surveys_assigned',
        headerName: 'Surveys Assigned To',
        valueGetter: (row) => portalHelper.removeHTMLTags(projectCodesRenderer(row)),
        cellRenderer: projectCodesRenderer,
        filter: 'agSetColumnFilter',
        filterParams: {
          valueGetter: projectCodesFilterValueGetter
        }
      },
      {
        field: 'surveys_with_pending_clarification_requests',
        headerName: 'Clarification Request Pending For',
        valueGetter: (row) => portalHelper.removeHTMLTags(projectCodesRenderer(row)),
        cellRenderer: projectCodesRenderer,
        filter: 'agSetColumnFilter',
        filterParams: {
          valueGetter: projectCodesFilterValueGetter
        }
      },
      {
        field: 'surveys_in_progress',
        headerName: 'Surveys In Progress',
        valueGetter: (row) => portalHelper.removeHTMLTags(projectCodesRenderer(row)),
        cellRenderer: projectCodesRenderer,
        filter: 'agSetColumnFilter',
        filterParams: {
          valueGetter: projectCodesFilterValueGetter
        }
      },
      {
        field: 'total_surveys_with_payer',
        headerName: 'Total Surveys',
        cellClass: 'numberType',
        columnGroupShow: 'always',
        filter: 'agNumberColumnFilter'
      },
      {
        field: 'scientific_paper_publication_elaboration',
        headerName: 'Elaborate Scientific paper publication',
        filter: 'agNumberColumnFilter'
      },
      {
        field: 'last_agreement_status',
        headerName: 'Last Agreement Status',
        valueGetter: (row) => portalHelper.removeHTMLTags(portalHelper.getLastAgreementInfo(row.data.meta__payer)),
        columnGroupShow: 'always',
        filter: 'agSetColumnFilter'
      },
      { field: 'agreement_signed_in', headerName: 'Agreement Signed In'},
      { field: 'agreement_bypassed_by', headerName: 'Agreement Bypassed By'},
      { field: 'agreement_bypassed_reason', headerName: 'Agreement Bypassed Reason'},
      { field: 'agreement_declined_reason', headerName: 'Agreement Declined Reason', filter: false},
      {
        field: 'terms_last_accepted_datetime',
        headerName: 'Terms Of Use',
        cellClass: 'dateType',
        valueFormatter: dateFormatter,
        comparator: dateTimeComparator,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateComparator,
          valueFormatter: dateFormatter
        }
      },
      {
        field: 'last_assignment_sent_datetime',
        headerName: 'Last Sent Assignment',
        cellClass: 'dateType',
        valueFormatter: dateFormatter,
        comparator: dateTimeComparator,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateComparator,
          valueFormatter: dateFormatter
        }
      },
      { field: 'months_since_joined_formatted', headerName: 'Months In RPR Network'},
      {
        field: 'form_sent_datetime',
        headerName: 'Form Sent On',
        cellClass: 'dateType',
        valueFormatter: dateFormatter,
        comparator: dateTimeComparator,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateComparator,
          valueFormatter: dateFormatter
        }
      },
      {
        field: 'form_submitted_datetime',
        headerName: 'Form Submitted On',
        cellClass: 'dateType',
        valueGetter: dateValueGetter,
        valueFormatter: dateFormatter,
        comparator: dateTimeComparator,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateComparator
        }
      },
      {
        field: 'form_updated_datetime',
        headerName: 'Form Updated On',
        cellClass: 'dateType',
        valueFormatter: dateFormatter,
        comparator: dateTimeComparator,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateComparator,
          valueFormatter: dateFormatter
        }
      },
      { field: 'form_updated_by', headerName: 'Form Updated by'},
      {
        field: 'is_promotional_use_allowed',
        headerName: 'Promotional Use of Data',
        valueGetter: promotionalUseValueGetter,
        filter: 'agSetColumnFilter'
      },
      {
        field: 'promotional_use_updated_datetime',
        headerName: 'Promotional Use Date',
        cellClass: 'dateType',
        valueFormatter: dateFormatter,
        comparator: dateTimeComparator,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateComparator,
          valueFormatter: dateFormatter
        }
      },
      {
        field: 'agreement_bypassed_signed_declined_voided_on',
        headerName: 'Agreement Bypassed/Signed/Declined/Voided on',
        cellClass: 'dateType',
        valueFormatter: dateFormatter,
        comparator: dateTimeComparator,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateComparator,
          valueFormatter: dateFormatter
        }
      },
      {
        field: 'payer_advisory_activity',
        headerName: 'Payer-advisory activities',
        filterParams: {
          valueGetter: csvSplitterValueGetter
        }
      },
      { field: 'payer_advising_capacity', headerName: 'Payer-advisory capacity Role' },
      { field: 'payer_advising_duties_description', headerName: 'Payer Advising Duties' },
      {
        field: 'rpr_creation_datetime',
        headerName: 'RPR Creation Date',
        cellClass: 'dateType',
        valueFormatter: dateFormatterOnlyDate,
        comparator: dateTimeComparator,
        columnGroupShow: 'always',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateComparator,
          valueFormatter: dateFormatterOnlyDate
        }
      },
      {
        field: 'short_profile',
        enableRowGroup: false,
        headerName: 'Short Biography',
        cellRenderer: truncateStr,
        tooltipField: 'short_profile',
        getQuickFilterText: getDefaultQuickFilterText,
        filter: false
      },
      { field: 'total_surveys', headerName: 'Total Surveys', columnGroupShow: 'always', cellClass: 'numberType'},
      { field: 'payer_created_by_full_name', headerName: 'Created By'}
    ];

    let getPayerColDef = function (fieldName) {
      return _.find(PAYER_EXPERTISE_COLUMNS, {field: fieldName});
    };

    let commonPayerColumnsDefs = [
      getPayerColDef('Id'),
      getPayerColDef('assignment'),
      getPayerColDef('shortlisted_id'),
      getPayerColDef('actions'),
      getPayerColDef('meta__salutation'),
      getPayerColDef('firstname'),
      getPayerColDef('lastname'),
      getPayerColDef('email'),
      {
        headerName: 'General',
        children: [
          getPayerColDef('meta__active'),
          getPayerColDef('meta__locked'),
          getPayerColDef('meta__do_not_contact'),
          getPayerColDef('payer_created_by_full_name'),
          getPayerColDef('meta__notes'),
          getPayerColDef('alternate_email_id'),
          getPayerColDef('mobilephone'),
          getPayerColDef('meta__respondent_type'),
          getPayerColDef('archetypes_used'),
          getPayerColDef('meta__payer_type'),
          getPayerColDef('meta__payer_identifiers'),
          getPayerColDef('country_of_service'),
          getPayerColDef('country_of_residence'),
          getPayerColDef('additional_information'),
          getPayerColDef('profile'),
          getPayerColDef('meta__new_payer'),
          getPayerColDef('anonymized_profile'),
          getPayerColDef('linkedin_url'),
          getPayerColDef('meta__timezone')
        ]
      },
      {
        headerName: 'Remuneration And Agreement',
        children: [
          getPayerColDef('remuneration_rate'),
          getPayerColDef('remuneration_currency'),
          getPayerColDef('payment_currency'),
          getPayerColDef('last_agreement_status'),
          getPayerColDef('agreement_signed_in'),
          getPayerColDef('agreement_bypassed_signed_declined_voided_on'),
          getPayerColDef('agreement_bypassed_by'),
          getPayerColDef('agreement_bypassed_reason'),
          getPayerColDef('agreement_declined_reason'),
          getPayerColDef('terms_last_accepted_datetime'),
          getPayerColDef('is_promotional_use_allowed'),
          getPayerColDef('promotional_use_updated_datetime')
        ]
      },
      {
        headerName: 'Expertise Form Details',
        children: [
          getPayerColDef('meta__is_payer_existing_or_potential'),
          getPayerColDef('expertise_form_status'),
          getPayerColDef('form_requested_by_full_name'),
          getPayerColDef('form_generated_datetime'),
          getPayerColDef('form_sent_datetime'),
          getPayerColDef('form_submitted_datetime'),
          getPayerColDef('form_submitted_by_full_name'),
          getPayerColDef('form_updated_datetime'),
          getPayerColDef('form_updated_by_full_name')
        ]
      },
      {
        headerName: 'Survey Assignment',
        children: [
          getPayerColDef('surveys_invited'),
          getPayerColDef('surveys_assigned'),
          getPayerColDef('surveys_in_progress'),
          getPayerColDef('surveys_with_pending_clarification_requests'),
          getPayerColDef('total_surveys_with_payer')
        ]
      },
      {
        headerName: 'Survey/Payer Statistics',
        children: [
          getPayerColDef('rpr_creation_datetime'),
          getPayerColDef('months_since_joined_formatted'),
          getPayerColDef('days_since_last_participation'),
          getPayerColDef('last_assignment_sent_datetime'),
          getPayerColDef('assignments_sent_count'),
          getPayerColDef('assignments_accepted_count'),
          getPayerColDef('acceptance_percentage'),
          getPayerColDef('completed_survey_count'),
          getPayerColDef('completion_percentage'),
          getPayerColDef('completion_on_time_percentage'),
          getPayerColDef('avg_days_response'),
          getPayerColDef('surveys_per_month'),
          getPayerColDef('surveys_in_last_month'),
          getPayerColDef('surveys_in_last_3_months'),
          getPayerColDef('surveys_in_last_6_months'),
          getPayerColDef('surveys_in_last_year'),
          getPayerColDef('clarifications_requested_count'),
          getPayerColDef('clarifications_requested_count_for_last_5_surveys')
        ]
      },
      {
        headerName: 'Relevance Rating',
        children: [
          getPayerColDef('relevance_rating_last_survey'),
          getPayerColDef('relevance_rating_last_three_survey')
        ]
      },
      getPayerColDef('is_healthcare_provider'),
      getPayerColDef('localities')
    ];

    payerExpertiseService.getGlobalFormColDefs = function () {

      let globalFormColDefs = [
        {
          headerName: 'Global Expertise Form Details',
          children: [
            getPayerColDef('company'),
            getPayerColDef('jobtitle'),
            getPayerColDef('years_of_experience'),
            getPayerColDef('professional_qualifications'),
            getPayerColDef('payer_position_category'),
            getPayerColDef('experiential_expertise'),
            getPayerColDef('clinical_functional_expertise'),
            getPayerColDef('coverage_functional_expertise'),
            getPayerColDef('economic_evaluation_functional_expertise'),
            getPayerColDef('pricing_functional_expertise'),
            getPayerColDef('tendering_contracting_functional_expertise'),
            getPayerColDef('hta_functional_expertise'),
            getPayerColDef('private_payer_functional_expertise'),
            getPayerColDef('public_payer_functional_expertise'),
            getPayerColDef('reimbursement_functional_expertise'),
            getPayerColDef('disease_category'),
            getPayerColDef('primary_expertise'),
            getPayerColDef('secondary_expertise'),
            getPayerColDef('primary_expertise_elaboration'),
            getPayerColDef('secondary_expertise_elaboration'),
            getPayerColDef('short_profile')
          ]
        }
      ];
      return angular.copy(commonPayerColumnsDefs.concat(globalFormColDefs));
    };

    payerExpertiseService.getKolFormColDefs = function () {
      let kolFormColDefs = [
        getPayerColDef('meta__payer_director_type'),
        {
          headerName: 'Physician/Clinical Expertise Form Details',
          children: [
            getPayerColDef('company'),
            getPayerColDef('jobtitle'),
            getPayerColDef('years_of_experience'),
            getPayerColDef('professional_qualifications'),
            getPayerColDef('state'),
            getPayerColDef('patients_treated_per_month'),
            getPayerColDef('experiential_expertise'),
            getPayerColDef('kol_disease_category'),
            getPayerColDef('disease_areas'),
            getPayerColDef('patient_type'),
            getPayerColDef('key_responsibilities'),
            getPayerColDef('scientific_paper_publication_elaboration'),
            getPayerColDef('payer_advisory_activity'),
            getPayerColDef('payer_advising_capacity'),
            getPayerColDef('payer_advising_duties_description'),
            getPayerColDef('short_profile')
          ]
        }];

      return angular.copy(commonPayerColumnsDefs.concat(kolFormColDefs));
    };

    payerExpertiseService.getUSFormColDefs = function () {
      var usFormColDefs = [
        getPayerColDef('meta__payer_director_type'),
        {
          headerName: 'US Expertise Form Details',
          children: [
            getPayerColDef('professional_qualifications'),
            getPayerColDef('experiential_expertise'),
            getPayerColDef('clinical_functional_expertise'),
            getPayerColDef('coverage_functional_expertise'),
            getPayerColDef('economic_evaluation_functional_expertise'),
            getPayerColDef('pricing_functional_expertise'),
            getPayerColDef('tendering_contracting_functional_expertise'),
            getPayerColDef('hta_functional_expertise'),
            getPayerColDef('private_payer_functional_expertise'),
            getPayerColDef('public_payer_functional_expertise'),
            getPayerColDef('reimbursement_functional_expertise'),
            getPayerColDef('disease_category'),
            getPayerColDef('payer_type'),
            getPayerColDef('company'),
            getPayerColDef('jobtitle'),
            getPayerColDef('organization_state'),
            getPayerColDef('years_of_experience'),
            getPayerColDef('government_employee'),
            getPayerColDef('prescribe_license'),
            getPayerColDef('vermont_practice_status'),
            getPayerColDef('us_organization_type'),
            getPayerColDef('former_organization'),
            getPayerColDef('former_jobtitle'),
            getPayerColDef('former_organization_leave_year'),
            getPayerColDef('former_organization_no_of_years'),
            getPayerColDef('former_organization_state'),
            getPayerColDef('idn_payers_business_side'),
            getPayerColDef('mco_current_voting_member'),
            getPayerColDef('mco_plan'),
            getPayerColDef('mco_responsibility'),
            getPayerColDef('mco_experience'),
            getPayerColDef('mco_plan_description'),
            getPayerColDef('managed_care_roles'),
            getPayerColDef('managed_care_roles_other'),
            getPayerColDef('role_description'),
            getPayerColDef('plan_total_no_lives'),
            getPayerColDef('plan_commercial_covered_lives'),
            getPayerColDef('plan_medicare_covered_lives'),
            getPayerColDef('medicare_with_part_d_advantage'),
            getPayerColDef('medicare_without_part_d_advantage'),
            getPayerColDef('medicare_standalone_part_d'),
            getPayerColDef('plan_managed_medicaid_covered_lives'),
            getPayerColDef('plan_exchange_covered_lives'),
            getPayerColDef('plan_veterans_covered_lives'),
            getPayerColDef('us_organization_role'),
            getPayerColDef('us_organization_role_years'),
            getPayerColDef('us_organization_buy_power'),
            getPayerColDef('hospital_organization_description'),
            getPayerColDef('hospital_organization_description_other'),
            getPayerColDef('is_eligible_340b_drug_pricing_program'),
            getPayerColDef('hospital_organization_specialties'),
            getPayerColDef('hospital_patient_care_type'),
            getPayerColDef('hospital_care_count'),
            getPayerColDef('state_federal_organization_role'),
            getPayerColDef('state_federal_organization_role_other'),
            getPayerColDef('state_federal_organization_description'),
            getPayerColDef('state_federal_organization_description_other'),
            getPayerColDef('organization_lives'),
            getPayerColDef('organization_jurisdiction_level'),
            getPayerColDef('organization_jurisdiction_level_other'),
            getPayerColDef('us_organization_description'),
            getPayerColDef('us_organization_patient_care_count'),
            getPayerColDef('short_profile')
          ]
        }
      ];

      return angular.copy(commonPayerColumnsDefs.concat(usFormColDefs));
    };

    payerExpertiseService.getAllFormColDefs = function () {
      let allFormColDefs = [
        getPayerColDef('meta__payer_director_type'),
        {
          headerName: 'All Expertise Details',
          children: [
            getPayerColDef('company'),
            getPayerColDef('jobtitle'),
            getPayerColDef('professional_qualifications'),
            getPayerColDef('experiential_expertise'),
            getPayerColDef('clinical_functional_expertise'),
            getPayerColDef('coverage_functional_expertise'),
            getPayerColDef('economic_evaluation_functional_expertise'),
            getPayerColDef('pricing_functional_expertise'),
            getPayerColDef('tendering_contracting_functional_expertise'),
            getPayerColDef('hta_functional_expertise'),
            getPayerColDef('private_payer_functional_expertise'),
            getPayerColDef('public_payer_functional_expertise'),
            getPayerColDef('reimbursement_functional_expertise'),
            getPayerColDef('disease_category'),
            getPayerColDef('years_of_experience'),
            getPayerColDef('payer_position_category'),
            getPayerColDef('primary_expertise'),
            getPayerColDef('secondary_expertise'),
            getPayerColDef('primary_expertise_elaboration'),
            getPayerColDef('secondary_expertise_elaboration'),
            getPayerColDef('payer_type'),
            getPayerColDef('organization_state'),
            getPayerColDef('government_employee'),
            getPayerColDef('prescribe_license'),
            getPayerColDef('vermont_practice_status'),
            getPayerColDef('us_organization_type'),
            getPayerColDef('former_organization'),
            getPayerColDef('former_jobtitle'),
            getPayerColDef('former_organization_leave_year'),
            getPayerColDef('former_organization_no_of_years'),
            getPayerColDef('former_organization_state'),
            getPayerColDef('idn_payers_business_side'),
            getPayerColDef('mco_current_voting_member'),
            getPayerColDef('mco_plan'),
            getPayerColDef('mco_responsibility'),
            getPayerColDef('mco_experience'),
            getPayerColDef('mco_plan_description'),
            getPayerColDef('managed_care_roles'),
            getPayerColDef('managed_care_roles_other'),
            getPayerColDef('role_description'),
            getPayerColDef('plan_total_no_lives'),
            getPayerColDef('plan_commercial_covered_lives'),
            getPayerColDef('plan_medicare_covered_lives'),
            getPayerColDef('medicare_with_part_d_advantage'),
            getPayerColDef('medicare_without_part_d_advantage'),
            getPayerColDef('medicare_standalone_part_d'),
            getPayerColDef('plan_managed_medicaid_covered_lives'),
            getPayerColDef('plan_exchange_covered_lives'),
            getPayerColDef('plan_veterans_covered_lives'),
            getPayerColDef('us_organization_role'),
            getPayerColDef('us_organization_role_years'),
            getPayerColDef('us_organization_buy_power'),
            getPayerColDef('hospital_organization_description'),
            getPayerColDef('hospital_organization_description_other'),
            getPayerColDef('is_eligible_340b_drug_pricing_program'),
            getPayerColDef('hospital_organization_specialties'),
            getPayerColDef('hospital_patient_care_type'),
            getPayerColDef('hospital_care_count'),
            getPayerColDef('state_federal_organization_role'),
            getPayerColDef('state_federal_organization_role_other'),
            getPayerColDef('state_federal_organization_description'),
            getPayerColDef('state_federal_organization_description_other'),
            getPayerColDef('organization_lives'),
            getPayerColDef('organization_jurisdiction_level'),
            getPayerColDef('organization_jurisdiction_level_other'),
            getPayerColDef('us_organization_description'),
            getPayerColDef('us_organization_patient_care_count'),
            getPayerColDef('state'),
            getPayerColDef('patients_treated_per_month'),
            getPayerColDef('kol_disease_category'),
            getPayerColDef('disease_areas'),
            getPayerColDef('patient_type'),
            getPayerColDef('key_responsibilities'),
            getPayerColDef('scientific_paper_publication_elaboration'),
            getPayerColDef('payer_advisory_activity'),
            getPayerColDef('payer_advising_capacity'),
            getPayerColDef('payer_advising_duties_description'),
            getPayerColDef('short_profile')
          ]
        }
      ];
      return angular.copy(commonPayerColumnsDefs.concat(allFormColDefs));
    };

  }
]);
