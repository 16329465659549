app.directive('responseDisplaySimpleGaborGranger', ['portalHelper', 'CONSTANTS',
  function (portalHelper, CONSTANTS) {
    return {
      scope: {
        question: '=',
        response: '=',
        disabled: '<',
        hideMeaning: '@',
        payerTitle: '@'
      },
      templateUrl: portalHelper.getUrlRev(
        'html_templates/directives/response_display_simple_gg.6f4d2675.html'),
      controller: function ($scope) {
        $scope.CONSTANTS = CONSTANTS;
        $scope.isMultiRationale = portalHelper.isMultiRationale;
        $scope.formatGaborGrangerQuestionText = portalHelper.formatGaborGrangerQuestionText;

        if ($scope.payerTitle == null) {
          $scope.payerTitle = 'Payer';
        }

        $scope.hasProperty = function (object, property_name) {
          return Object.prototype.hasOwnProperty.call(object, property_name);
        };

        $scope.isShowMeaning = function () {
          return ($scope.hideMeaning == null || $scope.hideMeaning.toLowerCase() == 'false');
        };
      }
    };
  }]);
