app.service('payerDetailsHelper', ['apiHelper', '$q', 'HttpAuth', 'portalHelper', '$filter', 'CONSTANTS', '$location',
  function (apiHelper, $q, HttpAuth, portalHelper, $filter, CONSTANTS, $location) {
    var payerDetailsHelper = this;

    payerDetailsHelper.get_payer_expertise_data = function (original_expertise, expertise_data, id_field_name) {
      var payer_expertise_data = [];

      for (var i = 0; i < original_expertise.length; i++) {
        var cur_exp_id = original_expertise[i]['id'];

        var flt_condition = {};
        flt_condition[id_field_name] = cur_exp_id;

        var cur_expertise_data = $filter('filter')(expertise_data, flt_condition, true);
        if (cur_expertise_data.length) {
          cur_expertise_data = cur_expertise_data[0];
        } else {
          cur_expertise_data = flt_condition;
        }

        if (cur_expertise_data.id) {
          cur_expertise_data['checked'] = true;
        }
        payer_expertise_data.push(angular.copy(cur_expertise_data));
      }

      return payer_expertise_data;
    };

    payerDetailsHelper.load_all_payers = function ($scope, is_admin, cb_success) {
      var promises = [];

      for (var i = 0; i < $scope.payer_count; i++) {
        var payer_number = i + 1;
        var url_payer_expertise = '';
        var url_payer_locality = '';

        url_payer_expertise = apiHelper.getApiUrl() + '/api/authoring/survey_country/' +
          $scope.survey_country_id + '/payer/' + payer_number + '/survey_payer_expertise.json';

        url_payer_locality = apiHelper.getApiUrl() + '/api/authoring/survey_country/' +
          $scope.survey_country_id + '/payer/' + payer_number + '/survey_payer_locality.json';

        promises.push(HttpAuth.get(url_payer_expertise));
        promises.push(HttpAuth.get(url_payer_locality));
      }

      $q.all(promises).then(
        function (result) {
          $scope.payer_expertise_list = [];
          $scope.payer_experiental_list = [];
          $scope.payer_locality_list = [];

          for (var i = 0; i < $scope.payer_count; i++) {
            var payer_number = i + 1;
            var cur_expertise_data_index = i * 2;
            var cur_locality_data_index = i * 2 + 1;

            var cur_payer_expertise_data = payerDetailsHelper.get_payer_expertise_data(
              $scope.expertise_list,
              result[cur_expertise_data_index].data,
              'expertise_id'
            );

            var cur_payer_experiental_data = payerDetailsHelper.get_payer_expertise_data(
              $scope.experiental_list,
              result[cur_expertise_data_index].data,
              'expertise_id'
            );

            var cur_payer_locality_data = payerDetailsHelper.get_payer_expertise_data(
              $scope.locality_list,
              result[cur_locality_data_index].data,
              'locality_id'
            );

            $scope.payer_expertise_list.push(angular.copy(cur_payer_expertise_data));
            $scope.payer_experiental_list.push(angular.copy(cur_payer_experiental_data));
            $scope.payer_locality_list.push(angular.copy(cur_payer_locality_data));
          }

          if (cb_success) {
            cb_success();
          }
        }, function (result) {
          portalHelper.showErrorCommon(result);
        }
      );
    };

    payerDetailsHelper.dialog_init_check_all = function ($scope, expertise_list, payer_data) {
      for (var i = 0; i < expertise_list.length; i++) {
        var exp_index = i;
        expertise_list[i].all_checked = true;

        for (var j = 0; j < $scope.cur_payer_range.length; j++) {
          var payer_number = $scope.cur_payer_range[j];
          if (!payer_data[payer_number - 1] || !payer_data[payer_number - 1][exp_index].checked) {
            expertise_list[i].all_checked = false;
            break;
          }
        }
      }
    };

    payerDetailsHelper.dialog_set_payer_range = function ($scope, page_number) {
      $scope.page_number = page_number;

      $scope.cur_num_payers = ($scope.page_number * $scope.payers_per_page > $scope.payer_count)
        ? ($scope.payer_count - ($scope.page_number - 1) * $scope.payers_per_page)
        : ($scope.page_number * $scope.payers_per_page - ($scope.page_number - 1) * $scope.payers_per_page);

      $scope.cur_payer_range = [];
      for (var i = 0; i < $scope.cur_num_payers; i++) {
        $scope.cur_payer_range.push(1 + ($scope.page_number - 1) * $scope.payers_per_page + i);
      }

      payerDetailsHelper.dialog_init_check_all($scope,
        $scope.expertise_list,
        $scope.payer_expertise_list);
      payerDetailsHelper.dialog_init_check_all($scope,
        $scope.experiental_list,
        $scope.payer_experiental_list);
      payerDetailsHelper.dialog_init_check_all($scope,
        $scope.locality_list,
        $scope.payer_locality_list);
    };

    payerDetailsHelper.attachDisplayValuesInRow = function (fields, row) {
      const NOW = moment();
      let fieldLen = fields.length;
      while (fieldLen--) {
        let response = fields[fieldLen];
        let displayValue =  response.value_display_text || response.value;
        if (displayValue && ('month_year' === response.field_type || 'year' === response.field_type)) {
          let dateMoment = response.field_type === 'month_year'
            ? moment('01 ' + response.value, 'DD MMM YYYY')
            : moment(response.value + '-01-01');

          displayValue = response.value + ' (' + portalHelper.calculate_date_diff(NOW, dateMoment) + ')';
        } else if ('checkbox' === response.field_type  || 'multi_select' === response.field_type) {
          if (portalHelper.has_json_format(displayValue)) {
            displayValue = JSON.parse(displayValue).join(', ');
          }
        }

        row[response.internal_name] = displayValue;
      }
    };

    payerDetailsHelper.convert_to_data_tables_format = function (rawData) {
      let result = [];
      let len = rawData.length;

      while (len--) {
        let row = {};
        let item = rawData[len];

        row.id = item.id;
        row.meta__is_payer_existing_or_potential = item.payer ? 'Existing': 'Potential';
        row.meta__form_requested_by_full_name = item.requested_by ? item.requested_by.full_name : 'RPR';
        row.meta__form_submitted_on = item.updated_datetime || item.submitted_datetime;
        row.meta__form_name = item.form_name;
        row.meta__form_type_id = item.form_type_id;
        row.meta__requested_by = item.requested_by;
        row.meta__payer = item.payer;
        row.meta__created_datetime = item.created_datetime;
        row.meta__payer_director_type = item.payer_director_type;

        payerDetailsHelper.attachDisplayValuesInRow(item.response, row);
        if (!row.full_name) {
          row.full_name = row.firstname + ' ' + row.lastname;
        }

        result.push(row);
      }

      return result;
    };

    const addLineBreaksAndIndentation = (str) => {
      const MAX_LINE_LENGTH = 30;
      const INDENTATION = '&emsp;&nbsp;'; 

      let lines = [];
      let currentLine = '';

      for (let i = 0; i < str.length; i++) {
        const char = str.charAt(i);

        if (currentLine.length >= MAX_LINE_LENGTH && char === ' ') {
          lines.push(currentLine.trim());
          currentLine = INDENTATION;
        }

        currentLine += char;
      }

      lines.push(currentLine.trim());

      return lines.join('<br/>');
    };


    payerDetailsHelper.getPinnedNotes = function (notes) {
      const pinnedNotes =
      notes
        .filter(note => note.is_pinned)
        .sort((noteA, noteB) => {
          if (noteA.created_datetime && noteB.created_datetime) {
            return new Date(noteB.created_datetime) - new Date(noteA.created_datetime);
          }
        })
        .map(pinnedNote => {
          if (pinnedNote.pinned_until_datetime) {
            const now = new Date();
            const pinnedUntilDatetime = new Date(pinnedNote.pinned_until_datetime);
            if (pinnedUntilDatetime < now) {
              return null;
            }
          }
          const formattedMessage = addLineBreaksAndIndentation(pinnedNote.message);

          const createdDate = moment(pinnedNote.created_datetime).format(CONSTANTS.dateTimeFormatMoment.MEDIUM_ONLY_DATE);
          return `<div style="line-height: 24px;"><i class="fa fa-fw fa-thumb-tack dark-blue me-1"></i><strong>${pinnedNote.created_by.first_name}</strong><span> (${createdDate})<span><div>&emsp;&nbsp;&nbsp;${formattedMessage}</div></div>`;
        });

      const filteredPinnedNotes = pinnedNotes.filter(note => note !== null);
      return filteredPinnedNotes.length > 0 ? filteredPinnedNotes : [];
    };

    payerDetailsHelper.getPinnedNotesHTMl = function (notes, id) {
      if (Array.isArray(notes) && notes.length >= 1) {
        var pinnedNotes = payerDetailsHelper.getPinnedNotes(notes);
        if (pinnedNotes.length > 0) {
          let notesHtml= pinnedNotes.map(note => `${note}`).join('\n');
          return `<div>${notesHtml}&emsp;&nbsp;&nbsp;<a href="/admin/#/Admin/Payer/View/${id}?active_tab=${CONSTANTS.adminPayerViewTabs.payer_notes}">Manage Pins</a></div>`;
        } else {
          return  `<div>${notes.length} notes added, 0 pinned. <a href="/admin/#/Admin/Payer/View/${id}?active_tab=${CONSTANTS.adminPayerViewTabs.payer_notes}">Pin notes</a></div>`;
        }
      }
      return ' ';
    };

    payerDetailsHelper.flatten_expertise_data = function (rawData) {
      var result = [];
      var len = rawData.length;

      while (len--) {
        var row = {};
        var item = rawData[len];

        row.id = item.id;
        row.form_response_id = item.form_response.id;
        row.archetypes_used = item.archetypes_used;
        row.meta__is_payer_existing_or_potential = item.payer_id ? 'Existing': 'Potential';
        row.meta__form_name = item.form_response.form_name;
        row.meta__form_type_id = item.form_response.form_type_id;
        row.meta__requested_by = item.form_response.requested_by;
        row.meta__payer = {
          id: item.payer_id,
          first_name : item.first_name || item.firstname,
          last_name : item.last_name || item.lastname,
          full_name: item.first_name + ' ' + item.last_name,
          active: item.meta_info.active,
          locked: item.meta_info.locked,
          live_surveys: item.meta_info.live_surveys
        };
        row.meta__info = item.meta_info;
        row.meta__created_datetime = item.form_response.created_datetime;
        row.meta__respondent_type = item.respondent_type;
        row.meta__payer_type = item.payer_type;
        row.meta__payer_identifiers = item.meta_info.payer_identifiers;
        row.meta__notes = payerDetailsHelper.getPinnedNotesHTMl(item.notes, item.payer_id);
        row.meta__payer.respondent_types = item.meta_info.respondent_types;
        row.meta__payer.country_of_expertise = item.country_of_expertise;

        if (Object.keys(item.meta_info).length) {
          row.meta__salutation = item.meta_info.title;
          row.meta__active = item.meta_info.active;
          row.meta__locked = item.meta_info.locked;
          row.meta__new_payer = item.meta_info.new_payer;
          row.meta__do_not_contact = item.meta_info.do_not_contact_reason;
          row.meta__timezone = item.meta_info.timezone;
          row.meta__profile = item.meta_info.profile || '';
          row.meta__additional_information = item.meta_info.additional_information || '';
          row.meta__payer_director_type = item.meta_info.payer_director_type;
          row.meta__last_login = item.meta_info.last_login;
          row.additional_information = item.meta_info.additional_information;
          row.profile = item.meta_info.profile;
          row.anonymized_profile = item.meta_info.anonymized_profile;
          row.linkedin_url = item.meta_info.linkedin_url;
        }

        if (Object.keys(item.contacts_view_json).length) {
          row.alternate_email_id = item.contacts_view_json.alternate_email;
          row.mobile = item.contacts_view_json.mobile;
          row.telephone = item.contacts_view_json.telephone;
          row.postal_address = item.contacts_view_json.postal_address;
          row.time_zone_relative = item.contacts_view_json.time_zone_relative;
        }

        if (Object.keys(item.honorarium_view_json).length) {
          row.remuneration_rate = item.honorarium_view_json.remuneration_rate;
          row.remuneration_currency = item.honorarium_view_json.remuneration_currency;
          row.payment_currency = item.honorarium_view_json.payment_currency;
          row.meta__payer.contract_status_label = item.honorarium_view_json.contract_status_label;
          let last_contract_details = item.honorarium_view_json.last_contract_details;
          if (last_contract_details) {
            row.meta__payer.last_contract_details = last_contract_details;
            row.last_agreement_status = last_contract_details;
            row.agreement_signed_in = last_contract_details.contract_source;
            row.agreement_bypassed_signed_declined_voided_on = (
              last_contract_details.signed_datetime ||
              last_contract_details.declined_datetime ||
              last_contract_details.voided_datetime
            );
            let contract_sign_bypassed_by = item.honorarium_view_json.contract_sign_bypassed_by;
            if (contract_sign_bypassed_by) {
              row.agreement_bypassed_by = contract_sign_bypassed_by.full_name;
            }
            row.agreement_declined_reason = last_contract_details.decline_reason;
          }
          row.agreement_bypassed_reason = item.honorarium_view_json.contract_bypassed_reason;
          row.terms_last_accepted_datetime = item.honorarium_view_json.terms_last_accepted_datetime;
          row.is_promotional_use_allowed = item.honorarium_view_json.is_promotional_use_allowed || '';
          row.promotional_use_updated_datetime = item.honorarium_view_json.promotional_use_updated_datetime || '';

          row.meta__payer.is_contract_bypassed_for_access = item.honorarium_view_json.is_contract_bypassed_for_access;
          row.meta__payer.is_contract_sign_required = item.honorarium_view_json.is_contract_sign_required;
          row.meta__payer.last_contract_requested_datetime = item.honorarium_view_json.last_contract_requested_datetime;
        }

        if (Object.keys(item.assignments_view_json).length) {
          row.surveys_invited = item.assignments_view_json.surveys_invited;
          row.surveys_assigned = item.assignments_view_json.surveys_assigned;
          row.surveys_in_progress = item.assignments_view_json.surveys_in_progress;
          row.total_surveys_with_payer = item.assignments_view_json.total_surveys_with_payer;
          row.surveys_with_pending_clarification_requests =
            item.assignments_view_json.surveys_with_pending_clarification_requests;
        }

        if (Object.keys(item.statistics_view_json).length) {
          row.acceptance_percentage = item.statistics_view_json.acceptance_percentage;
          row.assignments_accepted_count = item.statistics_view_json.assignments_accepted_count;
          row.assignments_sent_count = item.statistics_view_json.assignments_sent_count;
          row.avg_days_response = item.statistics_view_json.avg_days_response;
          row.completed_survey_count = item.statistics_view_json.completed_survey_count;
          row.completion_on_time_percentage = item.statistics_view_json.completion_on_time_percentage;
          row.completion_percentage = item.statistics_view_json.completion_percentage;
          row.rpr_creation_datetime = item.statistics_view_json.created_datetime;
          row.days_since_last_participation = item.statistics_view_json.days_since_last_participation;
          row.last_assignment_sent_datetime = item.statistics_view_json.last_assignment_sent_datetime;
          row.months_since_joined_formatted = item.statistics_view_json.months_since_joined_formatted;
          row.surveys_per_month = item.statistics_view_json.surveys_per_month;
          row.surveys_in_last_month = item.statistics_view_json.surveys_in_last_month;
          row.surveys_in_last_3_months = item.statistics_view_json.surveys_in_last_3_months;
          row.surveys_in_last_6_months = item.statistics_view_json.surveys_in_last_6_months;
          row.surveys_in_last_year = item.statistics_view_json.surveys_in_last_year;
          row.clarifications_requested_count = item.statistics_view_json.clarification_request_count;
          row.clarifications_requested_count_for_last_5_surveys = item.statistics_view_json.clarification_request_count_last_5_surveys;
        }

        if (Object.keys(item.relevance_rating_view_json).length) {
          row.relevance_rating_last_survey = item.relevance_rating_view_json.relevance_rating_last_survey;
          row.relevance_rating_last_three_survey = item.relevance_rating_view_json.relevance_rating_last_three_survey;
        }

        row.is_healthcare_provider = item.is_healthcare_provider;
        row.localities = item.localities;
        row.payer_created_by_full_name = item.payer_created_by_full_name || 'N/A';

        if (item.form_response) {
          row.expertise_form_status = item.form_response.status;
          row.form_requested_by_full_name = item.form_response.requested_by ? item.form_response.requested_by.full_name : 'RPR';
          row.form_generated_datetime = item.form_response.created_datetime;
          row.form_sent_datetime = item.form_response.sent_datetime;
          row.form_submitted_datetime = item.form_response.submitted_datetime;
          row.form_submitted_by_full_name = item.form_response.submitted_by ? item.form_response.submitted_by.full_name : '';
          row.form_updated_datetime = item.form_response.updated_datetime;
          row.form_updated_by_full_name = item.form_response.updated_by ? item.form_response.updated_by.full_name : '';
        }

        payerDetailsHelper.attachDisplayValuesInRow(item.form_response.response, row);

        if ('Physician/Clinical Expertise Form' === row.meta__form_name) {
          row['kol_disease_category'] = row['disease_category'];
          row['disease_category'] = '';
        }

        if (!row.full_name) {
          row.full_name = row.firstname + ' ' + row.lastname;
        }

        result.push(row);
      }

      return result;
    };
  }]
);
