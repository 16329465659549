app.service('licenceHelperService',
  ['CONSTANTS', '$filter',
    function (CONSTANTS, $filter) {
      var licenceHelper = this;

      licenceHelper.renderLicenceDetailsTable = function (licenceName, creditsTotal, fromDate,
        toDate, gracePeriodEndDate, creditsRemaining,
        daysRemaining, extraData) {
        var table = ['<table class="table-colored wide">',
          '<tr>',
          '<td><i class="fa fa-money fa-fw"></i>&nbsp;Licence Type</td>',
          '<td>' + licenceName + '</td></tr>',
          '<tr><td><i class="fa fa-dollar fa-fw"></i>&nbsp;Licence Credits</td>',
          '<td>' + creditsTotal.toLocaleString() + '</td></tr>',
          '<tr><td><i class="fa fa-calendar fa-fw"></i>&nbsp;Start Date</td>',
          '<td>' + $filter('date')(
            fromDate,
            CONSTANTS.dateTimeFormat.MEDIUM_ONLY_DATE) + ' GMT+0</td></tr>',
          '<tr><td><i class="fa fa-calendar fa-fw"></i>&nbsp;End Date</td>',
          '<td>' + $filter('date')(toDate, CONSTANTS.dateTimeFormat.MEDIUM_ONLY_DATE) + ' GMT+0</td></tr>'
        ];

        if (gracePeriodEndDate != null) {
          table = table.concat([
            '<tr><td><i class="fa fa-calendar fa-fw"></i>&nbsp;End Date with grace period</td>',
            '<td>' + $filter('date')(
              gracePeriodEndDate,
              CONSTANTS.dateTimeFormat.MEDIUM_ONLY_DATE) + '</td></tr>'
          ].join(''));
        }

        if (creditsRemaining != null) {
          table = table.concat([
            '<tr><td><i class="fa fa-th fa-fw"></i>&nbsp;Remaining Credits</td>',
            '<td>' + creditsRemaining.toLocaleString() + '</td></tr>'
          ]);
        }

        if (daysRemaining != null) {
          table = table.concat([
            '<tr><td><i class="fa fa-battery-quarter fa-fw"></i>&nbsp;Remaining Days</td>',
            '<td>' + daysRemaining.toLocaleString() + '</td></tr>'
          ]);
        }

        if (extraData != null && Array.isArray(extraData) && extraData.length > 0) {
          extraData.forEach(function (item, index) {
            table.push('<tr><td>' + item[0] + '</td><td>' + item[1] + '</td></tr>');
          });
        }

        table.push('</table>');
        return table.join('');
      };

      licenceHelper.doesProblematicLicenceExist = function(licences) {
        licences.forEach(function (l) {
          if (l.problems && l.problems.length > 0) {
            return true;
          }
        });
        return false;
      };

      licenceHelper.getProblemMessages = function (licences) {
        var problematic_licence_messages = [];
        if (licences == null || licences.length === 0) {
          return problematic_licence_messages;
        }

        licences.forEach(function (l) {
          if (l.problems && l.problems.length > 0) {
            Array.prototype.push.apply(problematic_licence_messages, l.problems);
          }
        });

        return problematic_licence_messages;
      };
    }]
);
