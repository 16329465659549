app.directive('adminProjectBrief',
  ['CONSTANTS', 'portalHelper', 'multiselects', 'apiHelper', 'HttpAuth', '$ngConfirm', 'payerDetailsHelper', '$timeout', '$filter', '$rootScope',
    function (CONSTANTS, portalHelper, multiselects, apiHelper, HttpAuth, $ngConfirm, payerDetailsHelper, $timeout, $filter, $rootScope) {
      return {
        scope: {
          survey: '=',
          project: '=',
          adminUsers: '=',
          payerLists: '=',
          loadData: '&',
          viewOnly: '@',
          title: '@'
        },
        templateUrl: portalHelper.getUrlRev(
          'html_templates/directives/admin_project_brief_form.5799fcea.html'),
        controller: function ($scope) {

          $scope.selected = {};
          $scope.selected.admins = [];

          $scope.has_configured_questions = function () {
            if (!$scope.survey || !$scope.survey.countries) {
              return false;
            }

            for (var i = 0; i < $scope.survey.countries.length; i++) {
              if ($scope.survey.countries[i].question_count) {
                return true;
              }
            }
            return false;
          };

          HttpAuth.get(apiHelper.getApiUrl() + '/api/authoring/project/lists/').then(
            function(result){
              $scope.project_lists = result.data;
            }
          );

          $scope.multiSelectSettings = multiselects.getMultiSelectSettings(50,
            ['first_name', 'last_name']);
          $scope.multiSelectTexts = { buttonDefaultText: '' };
          $scope.CONSTANTS = CONSTANTS;

          $scope.expertise_list = $scope.payerLists.functional_expertise;
          $scope.locality_list = $scope.payerLists.locality;
          $scope.experiental_list = $scope.payerLists.experiential_expertise;

          $scope.edit_countries = function () {
            $scope.edit_countries_mode = (!$scope.edit_countries_mode);
          };

          $scope.delete_country_confirm = function (c) {
            portalHelper.showPopupYesNo('',
              'Are you sure you want to delete this country?',
              function () {
                $scope.delete_country(c);
              });
          };

          $scope.export_credit_statement = function (survey_id) {
            portalHelper.downloadFileWithUrl(
              '/reports/survey/statement/xlsx/' + survey_id + '/'
            );
          };

          $scope.delete_country = function (c) {
            var url = apiHelper.getApiUrl() + '/api/admin/survey_country/' + c.survey_country_id + '.json';

            HttpAuth.delete(url, {}).then(function (result) {
              $scope.loadData();
              portalHelper.showPopupOK('Deleted', 'Country deleted successfully');
            }, function (result) {
              portalHelper.showErrorCommon(result);
            });
          };

          $scope.renderCreditAdjustments = function () {
            $('#credit-adjustments').DataTable({
              bDestroy: true,
              autoWidth: false,
              ordering: true,
              dom: 'rtip',
              data: $scope.project.credit_adjustment,
              scrollX: false,
              columns: [
                {
                  title: 'Changed By',
                  data: 'added_by',
                  render: function (data, type, row) {
                    return data.first_name + ' ' + data.last_name;
                  }
                },
                {
                  title: 'Licence ID',
                  data: 'licence_code'
                },
                {
                  title: 'Credits',
                  data: 'amount',
                  className: 'dt-number'
                },
                {
                  title: 'Date',
                  data: 'added_datetime',
                  render: function (data, type, row) {
                    return $filter('date')(data, CONSTANTS.dateTimeFormat.MEDIUM_24H_TIME_NO_SEC);
                  }
                },
                {
                  title: 'Reason',
                  data: 'reason'
                }
              ]
            });
          };

          $scope.$watch('survey', function () {
            $timeout(function () {
              $scope.has_deletable_countries = false;

              if (!$scope.survey.countries) {
                return;
              }

              for (var i = 0; i < $scope.survey.countries.length; i++) {
                if ($scope.survey.countries[i].is_deletable) {
                  $scope.has_deletable_countries = true;
                  break;
                }
              }
            });
          });

          $scope.$watch('project', function () {
            $timeout(function () {
              $scope.renderCreditAdjustments();

              $scope.selected.admins = multiselects.get_multi_select_model(
                $scope.project.operations_team,
                $scope.adminUsers
              );
            });
          });

          $scope.survey.start_date = new Date($scope.survey.start_date);

          $scope.validate_project = function () {
            var result = true;
            $scope.error_list = [];

            if (!$scope.project.licence_code) {
              $scope.error_list.push(
                'Please enter licence code part of project code as 2 digit positive integer');
              result = false;
            }

            if (!$scope.project.code || $scope.project.code == 0) {
              $scope.error_list.push('Please enter project code as positive integer');
              result = false;
            }

            return result;
          };

          $scope.save_project = function (silent_mode, success_cb, error_cb) {
            if (!$scope.validate_project()) {
              portalHelper.scrollToElementById('error_list');
              return;
            }

            $scope.project.operations_team = multiselects.get_multi_select_ids($scope.selected.admins);

            var url = apiHelper.getApiUrl() + '/api/admin/project/' + $scope.survey.project_id + '.json';

            HttpAuth.put(url, $scope.project).then(function (result) {
              if (!silent_mode) {
                portalHelper.showPopupOK('Saved', 'Project has been saved', function () {
                  $scope.loadData();
                });
              }

              if (success_cb) {
                success_cb();
              }
            }, function (result) {
              if (result.status == 400 && result.data.code) {
                $scope.error_list = [result.data.code];
                portalHelper.scrollToElementById('error_list');
                result = false;
              } else {
                portalHelper.showErrorCommon(result);
              }

              if (error_cb) {
                error_cb();
              }
            });
          };

          $scope.prevent_dblclick = function () {
            return false;
          };

          $scope.approve_project = function () {
            $scope.is_processing = true;

            $scope.save_project(true, function () {
              var url = apiHelper.getApiUrl() + '/api/admin/project/approve/' + $scope.survey.project_id + '.json';

              HttpAuth.put(url, {}).then(function (result) {
                portalHelper.showPopupOK('Approved', 'Project has been approved');
                $scope.loadData();
                $scope.is_processing = false;
              }, function (result) {
                portalHelper.showErrorCommon(result);
                $scope.is_processing = false;
              });
            }, function () {
              $scope.is_processing = false;
            });
          };

          $scope.dialog_add_remove_credits = function (scope, bAdd) {
            scope.selected.error = '';

            if (scope.selected.amount === '') {
              scope.selected.error = 'Please enter the number of credits to allocate';
              return false;
            } else if (!angular.isDefined(scope.selected.amount)) {
              scope.selected.error = 'Credit amount: Ensure this value is valid integer greater than or equal to 1';
              return false;
            }

            if (!scope.selected.reason) {
              scope.selected.error = 'You must enter reason!';
              return false; 
            }

            if ((!scope.selected.licence) || (parseInt(scope.selected.licence) < 1)) {
              scope.selected.error = 'You must select a licence!';
              return false; 
            }

            var amount = (bAdd) ? scope.selected.amount : -scope.selected.amount;

            var url = apiHelper.getApiUrl() + '/api/admin/survey/credit.json';
            var data = {
              'survey': $scope.survey.id,
              'amount': amount,
              'reason': scope.selected.reason,
              'subscription': scope.selected.licence
            };

            HttpAuth.post(url, data).then(function (result) {
              $scope.loadData();
            }, function (result) {
              portalHelper.showErrorCommon(result);
            });
          };

          $scope.manage_credits_show = function () {
            var cur_scope = $rootScope.$new(true);
            cur_scope.popup_error = '';
            cur_scope.text_value = '';
            cur_scope.selected = { 'amount': '', 'subscription': '-1' };
            cur_scope.regex_positive_int = CONSTANTS.regex_positive_int;

            var licenceListUrl = apiHelper.getApiUrl() + '/api/admin/subscription.json?client=' + $scope.survey.client_id;
            HttpAuth.get(licenceListUrl).then(
              function (result) {
                cur_scope.licencesList = result.data.filter(
                  function (licence) {
                    return licence.has_usable_credits;
                  }
                );

                if ($scope.project.licence_code && cur_scope.licencesList.length > 0) {
                  var projectLicence = _.find(cur_scope.licencesList,
                    { 'licence_code': $scope.project.licence_code });
                  if (projectLicence) {
                    cur_scope.selected.licence = projectLicence.id;
                  }
                }

                $ngConfirm({
                  title: '',
                  contentUrl: portalHelper.getUrlRev(
                    'html_templates/dialogs/admin_project_manage_credits.03f1636b.html'),
                  columnClass: 'col-md-8',
                  scope: cur_scope,
                  buttons: {
                    Cancel: {
                      btnClass: 'mat ui critical button',
                      action: function (scope, button) {
                      }
                    },
                    Add: {
                      btnClass: 'mat ui primary button',
                      text: 'Add Credits',
                      action: function (scope, button) {
                        return $scope.dialog_add_remove_credits(scope, true);
                      }
                    },
                    Remove: {
                      btnClass: 'mat ui primary button',
                      text: 'Remove Credits',
                      action: function (scope, button) {
                        return $scope.dialog_add_remove_credits(scope, false);
                      }
                    }
                  }
                });

              }
            );

          };

          $scope.manage_credits = function () {
            if ($scope.viewOnly === 'true') {
              $scope.manage_credits_show();
            } else {
              $scope.save_project(true, $scope.manage_credits_show);
            }
          };

          $scope.reject_project_confirm = function () {
            $scope.popup_header1 = 'Reject Project';
            $scope.popup_header2 = 'Please enter a reason for rejection.';
            $scope.popup_error = '';
            $scope.text_value = '';

            $ngConfirm({
              title: '',
              type: 'red',
              contentUrl: portalHelper.getUrlRev('html_templates/dialogs/popup_with_text.95fa6ce2.html'),
              scope: $scope,
              buttons: {
                Submit: {
                  btnClass: 'mat ui primary button',
                  action: function (scope, button) {
                    $scope.popup_error = '';

                    if (!scope.text_value) {
                      scope.popup_error = 'You must enter a reason!';
                      return false; 
                    }

                    $scope.is_processing = true;

                    $scope.reject_project(scope.text_value, function () {
                      $scope.is_processing = false;
                    }, function () {
                      $scope.is_processing = false;
                    });
                  }
                },
                Cancel: {
                  btnClass: 'mat ui critical button',
                  action: function (scope, button) {
                  }
                }
              }
            });
          };

          $scope.reject_project = function (reason, success_cb, error_cb) {
            $scope.save_project(true, function () {
              var url = apiHelper.getApiUrl() + '/api/admin/project/reject/' + $scope.survey.project_id + '.json';

              HttpAuth.put(url, { 'reason': reason }).then(function (result) {
                portalHelper.showPopupOK('Rejected', 'Project has been successfully rejected');

                $scope.loadData();

                if (success_cb) {
                  success_cb();
                }
              }, function (result) {
                portalHelper.showErrorCommon(result);

                if (error_cb) {
                  error_cb();
                }
              });
            }, function () {

              if (error_cb) {
                error_cb();
              }
            });
          };

          $scope.manage_payer_details = function (survey_country) {
            $scope.payers_per_page = 10;
            $scope.survey_country_id = survey_country.survey_country_id;
            $scope.payer_count = survey_country.payer_count;

            $scope.change_payer_details_page = function (newPage) {
              payerDetailsHelper.dialog_set_payer_range($scope, newPage);
            };

            $scope.dialog = $ngConfirm({
              title: 'Manage ' + $rootScope.nomenclature_dict.stakeholder_title + ' Criteria Tags For \'' + survey_country.country.name + '\'',
              closeIcon: true,
              theme: 'igs-theme',
              columnClass: 'col-md-10',
              contentUrl: portalHelper.getUrlRev(
                'html_templates/dialogs/client_manage_payer_details.93138ea0.html'),
              onScopeReady: function (scope) {
                $scope.total_pages = Math.ceil($scope.payer_count / $scope.payers_per_page);
                payerDetailsHelper.load_all_payers($scope, true, function () {
                  payerDetailsHelper.dialog_set_payer_range($scope, 1);
                });
              },
              scope: $scope,
              buttons: {
                close: {
                  btnClass: 'mat ui secondary button',
                  text: 'Close',
                  action: function () {
                  }
                }
              }
            });
          };
        }
      };
    }]
);
