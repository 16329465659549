app.service('disclaimerService', [ 'CONSTANTS', 'portalHelper', '$rootScope',
  function (CONSTANTS, portalHelper, $rootScope) {
    var service = this;

    var createBasicDisclaimerElement = function (type, content, options) {
      return {
        type: type,
        content_html: content,
        options: options
      };
    };

    var createBasicDisclaimerOption = function (content, doesTerminateSurvey) {
      return {
        content_html: content,
        terminates_survey: doesTerminateSurvey
      };
    };

    var sanitizeElement = function (el) {
      var options = null;
      if (el.options != null && el.options.length > 0) {
        options = [];
        for (var p=0; p<el.options.length; p++) {
          options.push(createBasicDisclaimerOption(
            el.options[p].content_html, el.options[p].terminates_survey));
        }
      }

      return createBasicDisclaimerElement(el.type, el.content_html, options);
    };

    service.create_disclaimer_element = function (type, content, options, isNew) {
      var result = createBasicDisclaimerElement(type, content, options);

      result.id = portalHelper.generate_random_id();
      if (isNew === true) {
        result.is_new = true;
      }

      return result;
    };

    service.create_disclaimer_option = function (content, doesTerminateSurvey) {
      var result = createBasicDisclaimerOption(content, doesTerminateSurvey);

      result.id = portalHelper.generate_random_id();
      return result;
    };

    service.create_new_disclaimer = function (ownerId) {
      return {
        client_id: null,
        id: 0,
        name: '',
        owner_id: ownerId,
        elements: []
      };
    };

    service.sanitize_disclaimer = function (disclaimer) {
      if (disclaimer == null) {
        return null;
      }

      var result = this.create_new_disclaimer(disclaimer.owner_id);
      result.name = disclaimer.name;
      result.owner_id = disclaimer.owner_id;
      result.id = disclaimer.id;

      if (disclaimer.elements && disclaimer.elements.length > 0) {
        for (var i=0; i<disclaimer.elements.length; i++) {
          result.elements.push(sanitizeElement(disclaimer.elements[i]));
        }
      }

      return result;
    };

    service.prepare_disclaimer_for_ui = function (disclaimer) {
      var result = angular.copy(disclaimer);

      if (result.elements != null && result.elements.length > 0) {
        result.elements.forEach(function (el) {
          if (!el.id) {
            el.id = portalHelper.generate_random_id();
          }

          if (el.options != null && el.options.length > 0) {
            el.options.forEach(function (op) {
              if (!op.id) {
                op.id = portalHelper.generate_random_id();
              }
            });
          }
        });
      }

      return result;
    };

    service.validate_disclaimer_elements = function (disclaimerElements) {
      var result = {
        valid: true,
        message: ''
      };

      if (disclaimerElements == null || disclaimerElements.length === 0) {
        return result;
      }

      for (var i = 0; i < disclaimerElements.length; i++) {
        if (disclaimerElements[i].content_html === ''
          && disclaimerElements[i].type !== CONSTANTS.disclaimerElementTypes.PAGE_BREAK) {
          result.message = 'All elements must have some text';
          result.valid = false;
          return result;
        }

        if (disclaimerElements[i].type === CONSTANTS.disclaimerElementTypes.QUESTION) {
          var allOptionsTerminateSurvey = true;
          for (var j = 0; j < disclaimerElements[i].options.length; j++) {
            var option = disclaimerElements[i].options[j];
            allOptionsTerminateSurvey = allOptionsTerminateSurvey & option.terminates_survey;
            if (option.content_html === '' ) {
              result.valid = false;
              result.message = 'All question options must have some text.';
              return result;
            }
          }

          if (allOptionsTerminateSurvey) {
            result.message = 'One or more questions have all options that terminate the survey. Atleast one of the options in a question should let the' + $rootScope.nomenclature_dict.stakeholder_lower + 'continue.';
            result.valid = false;
            return result;
          }
        }
      }

      return result;
    };
  }]);

